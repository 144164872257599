import 'date-fns';
import 'dayjs/locale/fr';
import 'dayjs/locale/en-ca';
import React from 'react';
import { TextField } from '@material-ui/core';
import { FieldInfo } from '../../FieldInfo';
import useStyles from './styles';
import { useUserContext } from '../../../Services/Providers/UserContextProvider';
import { FormattedMessage } from 'react-intl';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LocaleHelper from '../../../Services/Helpers/LocaleHelper';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

export default function FormDatePicker({ name, placeholder, message, editState, title, width, editValue, InputChange, error, errorText, getValue, value, blur, required }) {
  const { userContextObj, setUserContext } = useUserContext();
  const classes = useStyles();
  const [dateError, setDateError] = React.useState('');

  React.useEffect(() => {
    InputChange({ target: { name, value: editValue } });
  }, []);

  const isValueValid = (value) => {
    if (value) {
      const year = value && value.year();
      const month = value && value.month();
      const day = value && value.date();
      return (year || year === 0) && (month || month === 0) && (day || day === 0);
    } else {
      return false;
    }
  };

  const handleChange = (value) => {
    if (isValueValid(value)) {
      const d = value.format(dateFormat);

      let event = { target: { name, value: d } };
      //blur(event);

      InputChange && InputChange(event);
    } else {
      let event = { target: { name, value: null } };
      //blur(event);

      InputChange && InputChange(event);
    }
    setUserContext({ interactedField: { name } });
    sendValue(value);
  };

  const sendValue = (value) => {
    if (isValueValid(value)) {
      const d = value.format(dateFormat);
      getValue && getValue(name, d);
    } else {
      getValue && getValue(name, null);
    }
  };

  const pickerProps = {
    variant: `inline`,
    inputVariant: `outlined`,
    label: name,
    autoOk: true,
  };

  return (
    <>
      <div
        className={classes.root}
        style={{ flexBasis: !!width ? width : '100%', display: 'flex', alignItems: 'flex-start' }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={LocaleHelper.getSelectedLocale() === 'en' ? 'en-ca' : LocaleHelper.getSelectedLocale()}
        >
          {title && <div className={classes.title}>{title}</div>}
          <div className={classes.container}>
            <DatePicker
              {...pickerProps}
              slotProps={{
                layout: {
                  className: classes.datePicker,
                },
                textField:{
                    variant :'outlined',
                    className : classes.datePickerTextField, 
                    fullWidth : true,
                    onBlur : blur,
                    id : name, 
                    name, 
                    error : !!error, 
                    helperText : (errorText && <FormattedMessage id={errorText} />) || ' ', 
                    required : required
                }
              }}
              id='date-picker-inline'
              name={name}
              label={placeholder}
              value={dayjs(value, dateFormat)}
              onChange={(date) => {
                handleChange(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              inputProps={{
                className: classes.input,
              }}
              onError={(error) => {
                setDateError(error);
              }}
              onClose={() => setTimeout(() => blur({ target: { name, value: 'dummy' } }), 200)} //value is a dummy one, because handleblur in FormBuilder has a delay and the value is not important, and the real value will be taken from the props.value
              
            />
          </div>
        </LocalizationProvider>
        {message && <FieldInfo message={message} />}
      </div>
    </>
  );
}
