import { FormControl, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useUserContext } from '../../../Services/Providers/UserContextProvider';
import { FieldInfo } from '../../FieldInfo';
import useStyles from './styles';
function Input({
  type,
  styles,
  disabled,
  customClasses,
  value,
  editValue,
  focused,
  error,
  size,
  errorText,
  helperText,
  InputChange,
  width,
  name,
  blur,
  message,
  labelWidth,
  placeholder,
  label,
  maxLength = null,
  intl,
  info,
  required = false,
}) {
  const { setUserContext } = useUserContext();
  const defaultClasses = useStyles();
  const classes = !!customClasses ? customClasses() : defaultClasses;

  React.useEffect(() => {
    InputChange({ target: { name, value: editValue } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div
        className={classes.root}
        style={{ position: 'relative', flexBasis: !!width ? width : '100%', display: 'flex', alignItems: 'center' }}
        aria-live='polite'
      >
        <FormControl
          className={classes.Input}
          variant='outlined'
          style={{ flexBasis: '100%', width: '100%' }}
        >
          <TextField
            label={label ? label : placeholder}
            placeholder={label ? intl.formatMessage({ id: 'typeHere' }) : ''}
            variant='outlined'
            type={type}
            name={name}
            id={name}
            style={styles}
            inputProps={{ maxLength }}
            // size={!!size ? size : 'medium'}
            onChange={(e) => {
              setUserContext({ interactedField: { name } });
              InputChange(e);
            }}
            autoComplete='new-password'
            error={error}
            inputRef={input=>input && focused && input.focus()}
            value={value}
            disabled={disabled}
            helperText={((errorText || helperText) && <FormattedMessage id={errorText || helperText} />) || ' '}
            onBlur={blur}
            {...(!!focused ? { focused } : {})}
            required={required}
          />
        </FormControl>
        <div style={{ position: 'absolute', right: 19, top: 9 }}>{!!message && <FieldInfo message={message} info={info ?? 'info'}/>}</div>
      </div>
  );
}

Input.defaultProps = {
  customClasses: null,
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.object.isRequired,
  customClasses: PropTypes.object,
};

export default injectIntl(Input);
