import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 200,
    textAlign: 'left',
    // marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: '5px 0px',
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${theme.palette.disabled.main} !important`,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  placeholder: {
    color: theme.palette.primary.main,
    marginLeft: `${theme.spacing(2)}px`,
  },
  textareaControl: {
    width: '100%',
    margin: `${theme.spacing(2)}px`,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  datePicker: {
    '& .MuiPickersDay-root': {
      '&:hover, &:focus': {
        '&:not(.Mui-selected)': {
          backgroundColor: `${theme.palette.primary.transparent} !important`,
        },
      },
    },
    '& .MuiIconButton-root': {
      '&:hover': {
        backgroundColor: `${theme.palette.primary.transparent} !important`,
      },
    },
    '& .MuiPickersYear-yearButton': {
      '&:focus': {
        border: `2px solid ${theme.palette.border.button}`,
        '&:not(.Mui-selected)': {
          backgroundColor: `${theme.palette.primary.transparent} !important`,
        },
      },
      '&:hover': {
        border: `2px solid ${theme.palette.border.button}`,
        '&:not(.Mui-selected)': {
          backgroundColor: `${theme.palette.primary.transparent} !important`,
        },
      },
    },
    '& .Mui-focusVisible': {
      backgroundColor: `${theme.palette.primary.transparent} !important`,
    },
    '& .Mui-selected': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
    },
  },

  datePickerTextField: {
    '& label.Mui-focused':{
      color: theme.palette.primary.main
    }, 
    '& .MuiOutlinedInput-root':{
      '&.Mui-focused fieldset':{
        borderColor: theme.palette.primary.main
      }
    }
  },

  input: {
    // height: 40,
    // borderRadius: 10,
    // backgroundColor: '#fff',
    // borderStyle: 'solid',
    // borderWidth: 0.0,
    // borderColor: '#eaeaea',
    // '&:hover': { borderColor: theme.palette.primary.main },
    // boxShadow: 'none',
    // paddingLeft: 10,
  },
  error: { fontSize: '0.8em', color: 'rgb(244, 67, 54)', margin: 5, height: 20 },
  title: {
    fontSize: '0.8em',
    textAlign: 'left',
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
  validate: {
    alignItems: 'center',
  },
}));

export default useStyles;
