import { makeStyles, lighten } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[1],
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'flex-start !important',
    alignItems: 'center',
    margin: 0,
    borderRadius: `10px !important`,
    padding: `${theme.spacing(1)}px !important`,
    position: 'relative',
    backgroundColor: '#ffffff',
    '&:not(:focus)': {
      border: `2px solid #ffffff`,
    },
    '&:hover': {
      background: `${theme.palette.primary.transparent} !important`,
      '&:not(:focus)': {
        borderColor: `${theme.palette.primary.transparent}`,
      }
    },
    '&:focus': {
      background: `${theme.palette.primary.transparent} !important`,
    },
  },
  error: {
    borderColor: '#ff000026',
  },
  selected: {
    background: `${theme.palette.primary.transparent} !important`,
    '&:not(:focus)': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  imageWrapper: {
    width: '75px !important',
    height: '75px !important',
    marginTop: 5,
    color: `${theme.palette.text.secondary} !important`,
    boxShadow: theme.shadows[9],
    fontWeight: 'bold',
  },
  Name: {
    margin: 0,
    marginTop: 7,
    wordBreak: 'break-word',
    userSelect: 'none',
    lineHeight: '1.2rem',
    fontWeight: 600,
    fontSize: '0.9rem',
  },
  Description: {
    fontSize: '0.7em',
    margin: 0,
    wordBreak: 'break-all',
  },
}));

export default useStyles;
