import { Button, Card, CardActions, CardContent } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useStyles from './styles';
import { useEffect, useState } from 'react';
import StatusField from '../../Components/From/StatusField';

const BookingConfirmation = (props) => {

  const [pageTitle, setPageTitle] = useState('');
  const classes = useStyles();

  useEffect(()=>{
    setTimeout(() => {
      setPageTitle(<FormattedMessage id='appointmentBookingConfirmed' />);
    }, 1000);
  }, [])
  
  return (
    <div>
      <StatusField>{pageTitle}</StatusField>
      <Card
        className={classes.root}
        tabIndex='0'
      >
        <CardContent>
          <h1 className={classes.title}>
            <FormattedMessage id='appointmentBookingConfirmed' />
          </h1>
          <p>
            <FormattedMessage id='You can check, change or cancel your appointment online' />
          </p>
        </CardContent>
        <CardActions>
          <Button
            size='small'
            onClick={() => props.history.push('/appointments')}
            className={classes.upcomingButton}
            focusRipple={false}
          >
            <FormattedMessage id='Upcoming appointments' />
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default BookingConfirmation;
