import React from 'react'
import types from './data';

const SwitchComponent = ({ type = "switch", ...props }) => {
    return (
        <React.Fragment>
            {types[type](props)}
        </React.Fragment>
    )
}

export default SwitchComponent
