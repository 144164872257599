import React from 'react';
import { withStyles } from '@material-ui/core';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { asEntity } from '~/Hoc';
import useStyles from './styles';
import SelectPatient from './Partials/SelectPatient';
import InfoCard from './Partials/InfoCard';
import { UserContext } from '~/Services/Providers';
import Loading from '~/Components/Loading';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { postMessageHeight, postMessageScrollUp } from '../../Services/Helpers';
import endpoints from '../../Services/Api';
import StatusField from '../../Components/From/StatusField';
import { FormattedMessage } from 'react-intl';
import { Alert } from '@material-ui/lab';

class UserProfile extends React.Component {
  static contextType = UserContext;
  selectedPatientIndex = 0;
  pageTitle = '';
  data = this.context.userContextObj.patients;
  PersonalInfoKeys = [
    { key: 'firstName', icon: 'perm_identity' },
    { key: 'lastName', icon: 'perm_identity' },
    { key: 'email', icon: 'alternate_email' },
    { key: 'gender', icon: 'wc' },
    { key: 'cell', icon: 'stay_primary_portrait_icon' },
  ];
  AddressCardKeys = [
    { key: 'country', icon: 'public' },
    { key: 'province', icon: 'pin_drop' },
    { key: 'city', icon: 'location_city' },
    { key: 'postalCode', icon: 'markunread_mailbox_icon' },
    { key: 'details', icon: 'directions' },
  ];
  PersonalInfoCard = {};
  AddressCard = {};

  componentDidMount() {
    postMessageScrollUp();
    this.loading = true;
    const { userContextObj } = this.context;
    endpoints.getInfo.get({ accountId: userContextObj.accountId, accountName: userContextObj.accountName }).then(({ data }) => {
      const { hideDobField, hideInsuranceField, hideHomePhoneField, hideGenderField, hideAddressField } = data;
      const fieldToHide = [];
      if (hideDobField) fieldToHide.push('dateOfBirth');
      if (hideHomePhoneField) fieldToHide.push('phone');
      if (hideInsuranceField) {
        fieldToHide.push('medicalCard');
        fieldToHide.push('medicalCardExp');
      }
      if (hideGenderField) fieldToHide.push('gender');
      if (hideAddressField) {
        fieldToHide.push(...['streetNumber', 'streetName', 'unit', 'country', 'province', 'city', 'postalCode', 'details']);
      }
      const IndexToRemove = [];
      this.PersonalInfoKeys.forEach((info, ind) => {
        if (fieldToHide.indexOf(info.key) > -1) {
          IndexToRemove.push(ind);
        }
      });
      this.PersonalInfoKeys = this.PersonalInfoKeys.filter((info) => fieldToHide.indexOf(info.key) < 0);
      this.AddressCardKeys = this.AddressCardKeys.filter((add) => fieldToHide.indexOf(add.key) < 0);

      this.props.entityStore.get({ id: this.data[this.selectedPatientIndex].patientId });
    });
  }
  entityDidReceived(data) {
    if (data) {
      this.getLoading = false;
      this.loading = false;
      let patient = { ...data.patientInfo.patient };
      patient.gender = data.genders.gender[patient.gender];

      for (let i = 0; i < this.PersonalInfoKeys.length; ++i) {
        const key = this.PersonalInfoKeys[i].key;
        const icon = this.PersonalInfoKeys[i].icon;
        this.PersonalInfoCard[key] = { value: patient[key], icon: icon };
      }

      for (let i = 0; i < this.AddressCardKeys.length; ++i) {
        const key = this.AddressCardKeys[i].key;
        const icon = this.AddressCardKeys[i].icon;
        this.AddressCard[key] = {
          value: key === 'details' ? `${patient.streetNumber} ${patient.streetName} ${patient.unit}` : patient[key],
          icon: icon,
        };
      }
    }
    setTimeout(() => {
      this.pageTitle = <FormattedMessage id='profileDetails' />;
    }, 500);
  }
  entityDidCatch(problem) {}

  handleChange = (event = { target: { value: 0 } }) => {
    ReactGA.event({
      event: 'page_view',
      vPath: `/book/${localStorage.getItem('store')}/profile`,
      territory: 'en_CA',
      store_type: 'optics',
      booking_stage: 'profile',
      sas_type: 'wink',
      content_id: '/wink/book',
      store_name: localStorage.getItem('store'),
      view_state: 'change',
      booking_type: '',
      dpdItem: 'wink-15',
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        vPath: `/book/${localStorage.getItem('store')}/profile`,
        territory: 'en_CA',
        store_type: 'optics',
        booking_stage: 'profile',
        sas_type: 'wink',
        content_id: '/wink/book',
        store_name: localStorage.getItem('store'),
        view_state: 'change',
        booking_type: '',
        dpdItem: 'wink-15',
      },
    });
    this.selectedPatientIndex = event.target.value;
    this.PersonalInfoCard = {};
    this.AddressCard = {};
    this.props.entityStore.get({ id: this.data[this.selectedPatientIndex].patientId });
    this.getLoading = true;
  };

  render() {
    const { classes } = this.props;
    const selected = this.data[this.selectedPatientIndex];

    if (this.loading) return <Loading />;
    const isPersonalInfoCardEmpty = Object.keys(this.PersonalInfoCard).length === 0;
    const isAddressCardEmpty = Object.keys(this.AddressCard).length === 0;
    return (
      <div className={classes.root}>
        <StatusField>{this.pageTitle}</StatusField>
        <h1 className={classes.title}><FormattedMessage id='profileDetails' /></h1>
        <div className={classes.header}>
          <SelectPatient
            data={this.data}
            selectedPatientIndex={this.selectedPatientIndex}
            handleChange={this.handleChange}
          />
        </div>
        <div className={classes.content}>
          {this.getLoading && (
            <div className={classes.loading}>
              <Loading />
            </div>
          )}
          {!this.getLoading && (
            <div className={classes.info}>
              {!isPersonalInfoCardEmpty && (
                <InfoCard
                  data={this.PersonalInfoCard}
                  title='Personal_information'
                />
              )}
              {!isPersonalInfoCardEmpty && !isAddressCardEmpty && <div className={classes.separator}> </div>}
              {!isAddressCardEmpty && (
                <InfoCard
                  data={this.AddressCard}
                  title='Address_information'
                />
              )}
            </div>
          )}

          {/* ==== Info Alert Component ==== */}
          <div tabIndex={'0'}>
            <Alert severity="warning" style={{ marginTop: 30 }}>
              <FormattedMessage id="editProfileWarningAlertMessage" />
            </Alert>
          </div>
          
        </div>
      </div>
    );
  }
}

decorate(UserProfile, {
  data: observable,
  selectedPatientIndex: observable,
  PersonalInfoCard: observable,
  getLoading: observable,
  AddressCard: observable,
  pageTitle: observable,
});

export default withStyles(useStyles)(asEntity({ storeId: 'UserProfile' })(observer(UserProfile)));
