import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
    root: {
        borderTopWidth: 2,
        borderBottomWidth: 2,
        borderTopColor: theme.palette.primary.main,
        borderBottomColor: theme.palette.primary.main,
    },
}))
