import { makeStyles } from '@material-ui/core/styles';
import formStyles from '../../Screens/GeneralStyles/formStyle';

const buttonWidth = 150;
const buttonHeight = 50;
export default makeStyles((theme) => ({
  ...formStyles(theme),
  root: {
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderTopColor: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between',
  },

  navigationButton: {
    '&:focus': {
      boxShadow: 'none',
    },
    '&.MuiButtonBase-root': {
      borderRadius: '0px !important',
    },
    '&:first-of-type': {
      borderTopLeftRadius: '9999px !important',
      borderBottomLeftRadius: '9999px !important',
    },
    '&:last-of-type': {
      borderTopRightRadius: '9999px !important',
      borderBottomRightRadius: '9999px !important',
    },
    width: `${buttonWidth}px`,
    minHeight: buttonHeight,
    [theme.breakpoints.down('sm')]: {
      width: buttonWidth * 0.6,
      padding: '2px',
    },
  },
}));
