import React from 'react';
import { Header, Footer, LogoSection } from './Partials';
import useStyles from './styles';
import { withStyles } from '@material-ui/core';
import { UserContext } from '~/Services/Providers';
import { isEmpty } from '~/Services/Helpers';
import { asEntity } from '~/Hoc';
import { postMessageHeight } from '../../../Services/Helpers';

class Layout extends React.Component {
  static contextType = UserContext;
  companyLogo = '';

  handleAppointmentData = (data) => {
    this.companyLogo = data?.companyLogo;
    this.onlinebookingTitle = isEmpty(data?.onlinebookingTitle) ? 'Wink Online Booking' : data?.onlinebookingTitle;
  };

  componentWillMount() {
    const { userContextObj } = this.context;
    if (userContextObj.accountId && userContextObj.accountName) {
      this.props.entityStore.get({
        accountId: userContextObj.accountId,
        accountName: userContextObj.accountName,
        ...(userContextObj.storeId && { storeId: userContextObj.storeId }),
      });
    }
  }

  entityDidReceived(data) {
    this.handleAppointmentData(data);
    const { userContextObj, setUserContext } = this.context;
    setUserContext({ appointmentInfoData: data });
    setUserContext({ stores: data?.stores ?? [] });
  }

  render() {
    let { classes } = this.props;
    const { userContextObj } = this.context;
    let { image } = userContextObj;
    const { themename } = userContextObj;
    const isSepecsavers = themename && themename === 'Specsavers';
    return (
      <div
        className={classes.root}
        ref={(elem) => postMessageHeight(elem)}
      >
        <div className={classes.outerWrapper}>
          <div className={classes.wrapper}>
            {!isSepecsavers && (
              <LogoSection
                url={image || this.companyLogo}
                onlinebookingTitle={this.onlinebookingTitle}
              />
            )}
            <Header />
            <main className={classes.container}>{this.props.children}</main>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(asEntity({ storeId: 'Layout' })(Layout));
