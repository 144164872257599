import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    email: Yup.string()
        .trim()
        .required("Email is a required field")
        .email("Please enter a valid email"),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        email: "",
    },
};
export const formData = [
    { name: "email", type: "email", width: "98%", formInitFocus: true, required: true },
];
export const formDependency = {}