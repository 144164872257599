import React from "react"
import ContentLoader from "react-content-loader"

const DropdownLoader = (props) => (
    <ContentLoader
        speed={2}
        width="100%"
        height={60}
        viewBox="0 0 500 60"
        backgroundColor="#dddddd"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="10" y="11" rx="2" ry="2" width="360" height="52" />
    </ContentLoader>
)

export default DropdownLoader;