import React, { Component } from 'react';

import styles from './styles';

class Blocker extends Component {
  render() {
    return (
      <div style={styles.root}>
        <div className='animated fadeIn pt-1 text-center'>Loading...</div>
      </div>
    );
  }
}

export default Blocker;
