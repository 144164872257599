import React, { useEffect } from 'react';
import BookForm from './Partials/BookForm';
import useStyles from './styles';
import { useHistory } from "react-router-dom";
import { useUserContext } from '~/Services/Providers';
import * as Yup from "yup";
import { queryParamsToObject, canSplit } from '~/Services/Helpers';
import { validationEmailSchema } from '../../Services/SharedData/UrlValidationSchema';



const Book = ({ }) => {
  const classes = useStyles();
  const history = useHistory();
  const { userContextObj, setUserContext } = useUserContext();
  const isAuthenticated = userContextObj.isAuthenticated

  useEffect(() => {
    let valid = canSplit(window.location.href, '?');
    let settings = {};
    if (valid) {
      let [url, params] = window.location.href.split('?');
      settings = {
        baseURL: "http://testing.orderoptical.com/Web",
        lang: "en",
        ...queryParamsToObject(params)
      };
    }
    if (validationEmailSchema.isValidSync(settings)) {
      if(settings.type === "confirmEmail") history.push('/confirmEmailToken');
      else if (settings.type === "newPassword") history.push('/newPassword');
    }

  }, []);


  return (
    <div className={classes.root}>
      <BookForm history={history}
        userContextObj={userContextObj}
        save={setUserContext}
        isAuthenticated={isAuthenticated}
      />
    </div>
  )
}

export default Book
