/* eslint-disable eqeqeq */
import { FormControl, FormHelperText, InputLabel, NativeSelect, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import uuid from 'react-uuid';
import { DropdownLoader } from '~/Components/Loaders';
import { asEntity } from '~/Hoc';
import { isEmpty } from '../../../Services/Helpers';
import { FieldInfo } from '../../FieldInfo';
import Styles from './styles';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { UserContext } from '../../../Services/Providers/UserContextProvider';
import { FormattedMessage, injectIntl } from 'react-intl';

const DropDownWrapper = ({ children, classes, width }) => (
  <div
    className={classes.root}
    style={{ flexBasis: !!width ? width : '100%' }}
  >
    <div className={classes.dropdownControl}>{children}</div>
  </div>
);

class Dropdown extends React.Component {
  static contextType = UserContext;
  value = '';
  options = [];
  loading = false;
  defaultValue = !!this.props.editValue ? this.props.editValue : this.value;
  dataFetchError = false;
  code = uuid();

  state = {
    isSmallScreen: false,
  };

  resize = () => {
    if (window.innerWidth < 700) {
      if (!this.state.isSmallScreen) {
        this.setState({ isSmallScreen: true });
      }
    } else {
      if (this.state.isSmallScreen) {
        this.setState({ isSmallScreen: false });
      }
    }
  };

  componentDidMount = () => {
    if (!!this.props.options) {
      this.defaultValue = !isEmpty(this.props.editValue) ? this.props.options.find((element) => element.id === this.props.editValue) : this.value;

      this.options = this.props.options;
      this.value = this.defaultValue;
      this.props.InputChange(this.props.name, this.defaultValue?.id); //this.defaultValue.id
    } else {
      this.loading = true;

      // if (!isEmpty(this.props.optionsURI)){
      this.props.entityStore.get({ uri: this.props.optionsURI, body: {}, code: this.code });
      // }
    }
    this.cacheStore();
    window.addEventListener('resize', this.resize);
    this.resize();
  };

  componentDidUpdate(prevProps) {
    if (this.props.optionsURI !== prevProps.optionsURI) {
      this.loading = true;
      this.props.entityStore.get({ uri: this.props.optionsURI, body: {}, code: this.code });
    }

    if (this.props.options && (this.props.options !== prevProps.options || this.props.editValue !== prevProps.editValue)) {
      this.defaultValue = !isEmpty(this.props.editValue) ? this.props.options.find((element) => element.id == this.props.editValue) : this.value;
      this.options = this.props.options;
      this.value = this.defaultValue;
      this.props.InputChange(this.props.name, this.defaultValue?.id); //this.defaultValue.id
    }
    if (this.props.value !== prevProps.value && this.props.value && this.props.value.controlled) {
      this.handleChange('', this.props.value); //this.defaultValue.id
    }
  }

  handleSelectChange = (event) => {
    this.handleChange(
      event,
      this.options.find((opt) => '' + opt.id === '' + event.target.value)
    );
  };

  handleChange = (event, value) => {
    this.value = isEmpty(value) ? '' : value;
    let formikValue = isEmpty(value) ? '' : value.id; //value.id
    this.props.InputChange(this.props.name, formikValue);
    this.cacheStore();
    this.cacheAppointmentType();
    this.context.setUserContext({ interactedField: { name: this.props.name } });
  };

  cacheStore = () => {
    if (this.props.name == 'storeId') {
      let store = isEmpty(this.value) ? '' : this.options.find((element) => element.id === this.value.id);
      localStorage.setItem('store', store.name);
    }
  };

  cacheAppointmentType = () => {
    if (this.props.name == 'appointmentTypeId') {
      let AppointmentType = isEmpty(this.value) ? '' : this.options.find((element) => element.id === this.value.id);
      localStorage.setItem('ga_booking_type', AppointmentType?.googleAnalyticCode);

      ReactGA.event({
        event: 'button_click',
        content_id: '/wink/book',
        click: `${localStorage.getItem('ga_booking_type')}_${localStorage.getItem('booking_stage')}`,
        territory: 'en_CA',
        store_type: 'optics',
        sas_type: 'wink',
        booking_type: localStorage.getItem('ga_booking_type'),
        booking_stage: localStorage.getItem('booking_stage'),
        store_name: localStorage.getItem('store'),
        view_state: 'main',
        dpdItem: 'wink-20',
      });

      TagManager.dataLayer({
        dataLayer: {
          event: 'button_click',
          content_id: '/wink/book',
          click: `${localStorage.getItem('ga_booking_type')}_${localStorage.getItem('booking_stage')}`,
          territory: 'en_CA',
          store_type: 'optics',
          sas_type: 'wink',
          booking_type: localStorage.getItem('ga_booking_type'),
          booking_stage: localStorage.getItem('booking_stage'),
          store_name: localStorage.getItem('store'),
          view_state: 'main',
          dpdItem: 'wink-20',
        },
      });
    }
  };

  entityDidReceived(data) {
    if (!!data && this.code === data.code) {
      if (this.props.name === 'country') {
        localStorage.setItem('provinces', JSON.stringify(data.content.provinces));
      }
      if (this.props.parseData) this.options = this.props.parseData(data.content);
      else this.options = data.content;

      const length = toJS(this.options).length;

      for (let i = 0; i < length; i++) {
        if (this.options[i].id === this.props.editValue) {
          this.defaultValue = this.options[i];
        }
      }

      this.loading = false;
      this.props.InputChange(this.props.name, this.defaultValue.id);
      this.value = this.defaultValue;

      if (this.options.length === 1) {
        this.value = this.options[0];
        this.props.InputChange(this.props.name, this.options[0].id);
      }

      this.cacheStore();
    }
  }

  entityDidCatch(error) {
    if (error.code === this.code) {
      this.dataFetchError = true;
    }
  }

  render() {
    const { isSmallScreen } = this.state;
    let { error, errorText, disableClearIcon = false, name, width: fieldWidth, placeholder, blur, classes, focused = false, dropDownLoading = true, disabled, renderOption, intl } = this.props;
    let width = fieldWidth;

    const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'));

    if (!!fieldsToHide && fieldsToHide?.hideMap) {
      width = '98%';
    }

    if (this.dataFetchError) {
      return (
        <DropDownWrapper
          classes={classes}
          width={width}
        >
          <h5 className={classes.DataFetchError}> Error fetching {name} data ! </h5>
        </DropDownWrapper>
      );
    }

    if (this.loading && dropDownLoading) {
      return (
        <DropDownWrapper
          classes={classes}
          width={width}
        >
          <DropdownLoader />
        </DropDownWrapper>
      );
    }
    return (
      <DropDownWrapper
        classes={classes}
        width={width}
      >
        <div
          className={classes.DropdownParent}
          style={{ flexBasis: !!width ? width : '100%' }}
        >
          {isSmallScreen && (
            <FormControl fullWidth>
              <InputLabel
                variant='standard'
                htmlFor={`controlled-native-${this.props.name}`}
                shrink={true}
              >
                {placeholder}
              </InputLabel>
              <NativeSelect
                name={name}
                value={this.value && this.value.id}
                id={`controlled-native-${this.props.name}`}
                onChange={this.handleSelectChange}
                disabled={disabled || this.options.length === 1}
                onBlur={blur}
              >
                <option
                  value=''
                  disabled
                  className={classes.defaultOption}
                >
                  {intl.formatMessage({ id: 'selectAnOption' })}
                </option>
                {this.options.map((opt, ind) => (
                  <option
                    key={ind}
                    value={opt.id}
                  >
                    {renderOption ? renderOption(opt) : opt.name}
                  </option>
                ))}
              </NativeSelect>
              <FormHelperText error>{error ? <FormattedMessage id={errorText} /> : ' '}</FormHelperText>
            </FormControl>
          )}
        {!isSmallScreen && (  
            <>
              {(disabled || this.options.length === 1) && <span className={classes.HideArrow}></span>}
              <Autocomplete
                disabled={disabled || this.options.length === 1}
                id={`combo-box-${this.props.name}`}
                options={this.options.map((opt) => opt.id)}
                disableClearable={disableClearIcon}
                value={this.value?.id || null}
                onChange={(e, id) =>
                  this.handleChange(
                    e,
                    this.options.find((opt) => opt.id === id)
                  )
                }
                getOptionLabel={(id) => this.options.find((opt) => opt.id === id)?.name || ' '}
                style={{ flexBasis: '100%' }}
                renderOption={(id) => {
                  return renderOption ? renderOption(this.options.find((opt) => opt.id === id)) : <React.Fragment>{this.options.find((opt) => opt.id === id).name}</React.Fragment>;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={blur}
                    label={placeholder}
                    variant='outlined'
                    name={name}
                    error={error}
                    inputRef={(input) => input && focused && input.focus()}
                    helperText={error ? <FormattedMessage id={errorText} /> : ' '}
                    disabled={disabled || this.options.length === 1}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'one-time-code',
                    }}
                    required={this.props.required}
                  />
                )}
              />
            </>
          )}
          {this.props.message && <FieldInfo message={this.props.message} />}
        </div>
      </DropDownWrapper>
    );
  }
}

decorate(Dropdown, {
  options: observable,
  dataFetchError: observable,
});

export default injectIntl(withStyles(Styles)(asEntity({ storeId: 'GenericDropdown' })(observer(Dropdown))));
