export const useStyles = (theme) => ({
  AlertWrapper: {
    width: '95%',
    color: '#fdebeb',
    borderRadius: 10,
    minHeight: 60,
    marginTop: "1.8em",
    marginLeft: '2.5%',
    [theme.breakpoints.down('xs')] : { 
      width: '90%',
      marginLeft: '5%',
    }
  },
  Alert: {
    flexBasis: '100%',
    marginLeft: '0.5em',
    padding: "5px",

  },
  SVGWrapper: {
    display: "flex",
    backgroundColor: '#f9c2c2',
    width: 75,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    justifyContent : 'center',
    alignItems : 'center'

  },
  SVG: {
    width: 30,
    height: 30,
    flexBasis: '60%'
  }
});