export const useStyles = (theme) => ({
  AlertWrapper: {
    display: 'flex',
    border:`2px solid ${theme.palette.error.background2}`,
    borderRadius: 12,
    color: theme.palette.error.main,
    minHeight: 60,
    marginTop: "1.8em",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  Alert: {
    flexBasis: '100%',
    marginLeft: '0.5em',
    padding: "5px",

  },
  SVGWrapper: {
    display: "flex",
    backgroundColor: '#f9c2c2',
    width: 75,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    justifyContent : 'center',
    alignItems : 'center'

  },
  SVG: {
    width: 30,
    height: 30,
    flexBasis: '60%'
  }
});