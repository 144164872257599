import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import useStyles from './styles';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { useUserContext } from '../../../../../Services/Providers';
import { ButtonBase } from '@mui/material';
import { injectIntl } from 'react-intl';

const TimeSlotCard = ({ id, start, end, onFocus, onBlur, isSelected, onKeyDown, onClick, error, tabIndex, focused, 'aria-describedby': ariaDescribedby, intl }) => {
  const classes = useStyles();
  const buttonRef = useRef();
  useEffect(() => {
    if (focused) {
      buttonRef.current.focus();
    }
  }, [focused]);

  const { themename } = useUserContext().userContextObj;
  const isSepecsavers = themename && themename === 'Specsavers';
  const getFormattedTime = (time) => new Date(time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  const ariaLabel = getFormattedTime(start) + (!isSepecsavers ? '-' + getFormattedTime(end) : '') + (isSelected ? ' ' + intl.formatMessage({ id: 'selected' }) : '');
  return (
    <ButtonBase
      tabIndex={tabIndex}
      ref={buttonRef}
      type='button'
      role='radio'
      aria-checked = {isSelected}
      className={classNames(classes.root, { [classes.error]: error, [classes.hovered]: focused, [classes.selected]: isSelected })}
      onClick={onClick}
      aria-label={ariaLabel}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      id={id}
      {...(ariaDescribedby && { 'aria-describedby': ariaDescribedby })}
      focusRipple={false}
    >
      <ScheduleIcon className={classes.Icon} /> &nbsp;
      {getFormattedTime(start)} {!isSepecsavers && <span>-{getFormattedTime(end)}</span>}
    </ButtonBase>
  );
};

export default injectIntl(TimeSlotCard);
