import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8em',
    border: 'none !important',
    padding: 0,
    '&:focus': {
      '&:not(:hover)': { backgroundColor: 'white' },
      // border: `2px solid ${theme.palette.primary.main} !important`,
      // borderRadius: '5px !important',
      '& div:first-of-type': {
        outline: `2px auto ${theme.palette.primary.main}`,
      },
    },
  },
  ItemContent: {
    margin: '2px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    textTransform: 'capitalize',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  Icon: {
    fontSize: '1.2em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));


export default useStyles;