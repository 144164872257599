import { Button, Icon, Tooltip } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

const BackButton = ({ clickAction }) => {
  const classes = useStyles();

  return (
    <Tooltip title="Back">
      <Button
        aria-label="Back"
        onClick={clickAction}
        className={classes.backButton}
        focusRipple={false}
      >
        <div className={classes.iconWrapper}>
            <Icon className={classes.icon}>arrow_back_ios</Icon>
        </div>
      </Button>
     </Tooltip>
  );
};

export default BackButton;
