import formStyles from '../../../../Screens/GeneralStyles/formStyle';

const buttonWidth = 150;
const buttonHeight = 50;
const useStyles = (theme) => ({
    ...formStyles(theme),
    root: {
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: theme.spacing(2),
        paddingRight: 5,
        justifyContent: "space-between",
    },
    buttonGroup: {
        marginRight: theme.spacing(1)
    },
    navigationButton: {
        '&:focus': {
            boxShadow: 'none'
        },
        "&.MuiButtonBase-root": {
            borderRadius: '0px !important',
        },
        "&:first-of-type": {
            borderTopLeftRadius: '9999px !important',
            borderBottomLeftRadius: '9999px !important',
        },
        "&:last-of-type": {
            borderTopRightRadius: '9999px !important',
            borderBottomRightRadius: '9999px !important',
        },
        width: `${buttonWidth}px`,
        minHeight: buttonHeight,
        [theme.breakpoints.down('sm')]: {
            width: buttonWidth * 0.5,
        },
    }
});
export default useStyles;
