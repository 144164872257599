import React from 'react'
import { Button, Icon, CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl'
import { useUserContext } from '../../../Services/Providers'; 


function ActionButton({ title, loading, icon, customClass, type, onClick, variant, ...props }) {
    const {themename} = useUserContext().userContextObj;
    const isSepecsavers = themename && themename==="Specsavers";
    const startIcon = icon ?
        (loading ? <CircularProgress color="light" size={15} /> : <Icon > {icon} </Icon>) 
        : null;
    return (
        <Button
            variant={variant}
            color="primary"
            className={customClass}
            startIcon={(!isSepecsavers && startIcon) ? startIcon : null}
            type={type}
            disabled={loading}
            onClick={onClick}
            focusRipple={false}
        >
            <FormattedMessage id={title} />
        </Button>
    )
}

ActionButton.defaultProps = {
    variant: "outlined",
    type: "submit"
}

export default ActionButton
