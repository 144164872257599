import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ClearRounded from '@material-ui/icons/ClearRounded';
import PropTypes from 'prop-types';

const PswdReqTooltipComp = ({
  classes,
  getRequirementStatus,
  getUnmetRequirements,
  passwordRequirementsDetails,
  allRequirementsMet,
  metText
}) => {
  return (
    <div
      className={classes.tooltipText}
      aria-label={'Password requirements'}
    >
      {allRequirementsMet ? (
        <Typography variant='body1' style={{ color: '#fff' }}>
          <FormattedMessage id='allPasswordRequirementsMet' />
        </Typography>
      ) : (
        <>
          <Typography variant='body1' style={{ color: '#fff' }}>
            <FormattedMessage id='passwordCriteria' />
          </Typography>
          {getUnmetRequirements().map((requirement, index) => {
            const { status, label } = getRequirementStatus(requirement);

            return (
              <span
                key={index}
                className={`
                  ${classes.pswdReqTooltip}
                  ${status === metText ? classes.pswdReqTooltipSuccess : classes.pswdReqTooltipFail}
                `}
              >
                {status === metText ? (
                  <CheckCircleOutline
                    className={classes.criteriaIcon}
                    fontSize={'inherit'}
                    aria-hidden='true'
                  />
                ) : (
                  <ClearRounded
                    className={classes.criteriaIcon}
                    fontSize={'inherit'}
                    aria-hidden="true"
                  />
                )}
                {requirement.criteria}
              </span>
            );
          })}
        </>
      )}
    </div>
  );
};

export default memo(PswdReqTooltipComp);

// Prop Validations:
PswdReqTooltipComp.propTypes = {
  classes: PropTypes.object.isRequired,
  getRequirementStatus: PropTypes.func.isRequired,
  getUnmetRequirements: PropTypes.func.isRequired,
  passwordRequirementsDetails: PropTypes.string.isRequired,
  allRequirementsMet: PropTypes.bool.isRequired,
  metText: PropTypes.string.isRequired
};