export const ButtonsData = [
    {
        title: 'login',
        icon: 'exit_to_app',
        clickAction: `loginAction()`,
        isAuth: false,
        routes: ['/auth',"/select-patient" , "/confirmEmailToken"],
        reversedPermission: true,
    },
    {
        title: 'Upcoming appointments',
        clickAction: `goToPath("/appointments")`,
        isAuth: true,
        icon: 'local_hospital',
        routes: [ '/select-patient' , '/confirm-booking' , '/confirmEmailToken'],
        reversedPermission: true,
        pathname: '/appointments'
    },
    {
        title: 'profile',
        icon: 'face',
        clickAction: `goToPath("/patients")`,
        isAuth: true,
        routes: ['/appointments','/patients'],
        reversedPermission: false,
        pathname: '/patients'
    },
    {
        title: 'booking',
        icon: 'today',
        clickAction: `goToPath("/")`,
        isAuth: true,
        routes: ['/', '/select-patient' , '/confirm-booking' , '/confirmEmailToken'],
        reversedPermission: true,
    },
    {
        title: 'logout',
        icon: 'exit_to_app',
        clickAction: `logoutHandler()`,
        isAuth: true,
        routes: ['/select-patient' , '/confirmEmailToken'],
        reversedPermission: true,
    },
]
