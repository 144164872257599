import { makeStyles } from '@material-ui/core/styles'
import formStyles from '../../../../Screens/GeneralStyles/formStyle';

export default makeStyles(theme => ({
    ...formStyles(theme),
    root: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(9),
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            padding: `0px ${theme.spacing(4)}px`,
        }
    },
    controlHeight: {
        minHeight: "40vh",
        [theme.breakpoints.down('sm')]: {
            minHeight: "10vh"
        }
    },
    ActionsWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            flexBasis:"100%",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
        }
    },
    SubmitButton: {
        margin: "10px",
        width: theme.buttons.width,
        height: theme.buttons.height,
        [theme.breakpoints.down('sm')]: {
            width:"80%",
            height: theme.buttons.height * 0.85,
        }
    },
    newPatient: {
        display: "inline-block",
        color: "#777",
        textDecoration: "none",
        flexBasis: "200px",
        justifyContent: "center",
        '&:hover': {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            textDecoration: "underline",
        }
    },

    selectPatientDesc:{
        
    }

}))
