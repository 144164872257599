import { setApiLocale } from '../Api';

const getSelectedLocale = () => localStorage.getItem('locale') || 'en';

const setSelectedLocale = (locale) => {
  localStorage.setItem('locale', locale);
  setApiLocale(locale);
};
setApiLocale(getSelectedLocale());

const getSelectedLang = (langs, locale) => {
  if (!locale) {
    locale = getSelectedLocale();
  }
  return langs.find((lang) => lang.code === locale);
};

export default {
  getSelectedLocale,
  setSelectedLocale,
  getSelectedLang
};
