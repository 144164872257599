import React, { useState, useEffect } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./styles";
import SwitchComponent from "./Partials/SwitchComponent";
import dateFormat from "dateformat";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { baseGAObject, getDayPeriod, getGAOriginalDate } from "../../../Services/Helpers";
import { useUserContext } from "../../../Services/Providers/UserContextProvider";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";

function SwitchInput({ name, control, placeholder, editValue, InputChange, getValue, error, errorText, blur, width, ...rest }) {
  const { userContextObj, setUserContext } = useUserContext();
  const [Checked, setChecked] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    let value = !!editValue ? editValue : Checked;
    setChecked(value);
    sendValueFormik(value);
    sendValue(value);
  }, []);

  const handleChange = () => {
    setUserContext({ interactedField: { name } });
    if (name === "earlyRequest") {
      sendGAEventOnChange(!Checked);
    }
    sendValueFormik(!Checked);
    sendValue(!Checked);
    setChecked(!Checked);
  };

  const sendGAEventOnChange = (checked) => {
    //google analytics
    const { firstSelectedDate, firstSelectedDateLeadTime, firstSelectedAppointmnetSlotsNum, next7DaysAvailability, next14DaysAvailability } = rest;
    let selectedTime = rest.selectedTime;
    if (rest.selectedTime && rest.selectedTime.start) {
      selectedTime = rest.selectedTime.start;
    }

    const bookingStage = localStorage.getItem("booking_stage");

    const originalFields = getGAOriginalDate(bookingStage);

    const gaObject = {
      ...baseGAObject,
      ...{
        event: "button_click",
        content_id: "/wink/book",
        click: `earlier_appt_toggle_${checked ? "on" : "off"}`,
        territory: "en_CA",
        store_type: "optics",
        sas_type: "wink",
        booking_type: localStorage.getItem("ga_booking_type"),
        booking_stage: bookingStage,
        store_name: localStorage.getItem("store"),
        view_state: "main",
        first_available: firstSelectedDate ?? "",
        first_appt_lead_time: firstSelectedDateLeadTime,
        day_appt_count: firstSelectedAppointmnetSlotsNum || "",
        next_7_days_available: next7DaysAvailability || "",
        next_14_days_available: next14DaysAvailability || "",
        selected_date: selectedTime ? dayjs(selectedTime).format("DD/MM/YYYY") : "",
        selected_day: selectedTime ? dayjs(selectedTime).format("dddd") : "",
        selected_time: selectedTime ? dayjs(selectedTime).format("HH:mm") : "",
        selected_period: selectedTime ? getDayPeriod(dayjs(selectedTime)) : "",
        dpdItem: "wink-32",
      },
      ...originalFields,
    };
    ReactGA.event(gaObject);

    TagManager.dataLayer({
      dataLayer: gaObject,
    });
  };

  const sendValueFormik = (value) => {
    let event = { target: { name, value } };
    InputChange && InputChange(event);
  };

  const sendValue = (value) => {
    getValue && getValue(name, value);
  };
  return (
    <div style={{ flexBasis: !!width ? width : "100%" }} className={classes.root}>
      <FormControlLabel
        control={<SwitchComponent type={control} checked={Checked} onChange={handleChange} onBlur={blur} name={name} color="primary" />}
        style={{ color: error ? theme.palette.error.main : theme.palette.common.black }}
        label={placeholder}
      />
      <small style={{ color: theme.palette.error.main }}> {errorText && <FormattedMessage id={errorText} />} </small>
    </div>
  );
}

SwitchInput.defaultProps = {
  control: "switch",
};

export default SwitchInput;
