import formStyles from "../../../GeneralStyles/formStyle";
const useStyles = (theme) => ({
    ...formStyles(theme), 

    buttonContainer:{
        display:'flex',
        flexDirection: 'row',
        justifyContent:'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width:'100%'
        },
        position: 'relative',
    },
    infoButton:{
        position: 'absolute',
        right:theme.spacing(-1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});


export default useStyles;