import React from 'react';
import { Select, InputLabel, MenuItem, FormControl } from '@material-ui/core';

const SelectPatient = ({ data, selectedPatientIndex, handleChange }) => {

    return (
        <FormControl variant="outlined" style={{ width: '100%' }} >
            <InputLabel id="-outlined-label" htmlFor='input-id'>Patients</InputLabel>
            <Select
                labelId="-outlined-label"
                id="-outlined"
                variant="outlined"
                value={selectedPatientIndex}
                onChange={handleChange}
                label="Patients"
                color="primary"
                style={{ width: '100%' }}
                inputProps={{
                    id:'input-id'
                }}
            >
                {data.length > 0 && data.map((patient, index) => {
                    return <MenuItem key={index} value={index}>{patient.firstName + " " + patient.lastName}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default SelectPatient
