import formStyles from "~/Screens/GeneralStyles/formStyle";

const useStyles = (theme) => ({
    ...formStyles(theme),
    root: {
        textAlign: "center",
        paddingBottom: theme.spacing(3),
        display: "flex",
    },
    dropdownControl: {
        width: "100%",
        margin: `${theme.spacing(2)}px`
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    contact: {
        fontSize: '0.7em',
    },
    coloredMessage: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    // paper: {
    //     position: 'absolute', 
    //     width: "50%",
    //     backgroundColor: theme.palette.background.paper,
    //     border: '2px solid #000',
    //     boxShadow: theme.shadows[5],
    //     padding: theme.spacing(2, 4, 3),
    //     top: `30%`,
    //     left: `40%`,
    //     maxHeight: "450px",
    //     overflow: "scroll",
    //     transform: `translate(-30%, -40%)`,
    //     [theme.breakpoints.down('sm')]: {
    //         width: "60%",
    //         top:"0px",
    //         left: `50%`,
    //         transform: `translate(-50%, 10%)`,
    //     }
    // }
});


export default useStyles;