import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    cell: Yup.string().required()
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        cell: "",
    },
};
export const formData = [
    { name: "cell", type: "phone", width: "98%", formInitFocus: true, required: true, },
];
export const formDependency = {}