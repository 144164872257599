import React from 'react';
import useStyles from './styles';
import Icon from '@material-ui/core/Icon';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from '~/Services/Helpers';

const InfoCard = ({ data, title }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} tabIndex='0'>
            <p className={`${classes.cardTitle} ${classes.title}`}><FormattedMessage id={title} /></p>
            {Object.keys(data).map((key, index) => {
                const current = data[key];
                return (
                    <div className={classes.content}>
                        <Icon className={classes.icon}>{current.icon}</Icon>
                        <span className={classes.key}><FormattedMessage id={key} /></span>
                        <span className={classes.value}> &nbsp; :  &nbsp;
                            {current.value ? (current.value) : (<FormattedMessage id={"NA"} />)}
                        </span>
                    </div>)
            })}



        </div>
    )
}

export default InfoCard
