import React, { useEffect, useRef, useState } from 'react';
import useStyles from './styles';
import { Avatar, Tooltip } from '@material-ui/core';
import classnames from 'classnames';
import { useUserContext } from '../../../../../Services/Providers';
import endpoints from '../../../../../Services/Api';
import { ButtonBase } from '@mui/material';
import { injectIntl } from 'react-intl';

const ProfessionalCard = ({ id, avatar = 'user', name, description, onFocus, onBlur, onKeyDown, onSelect, isSelected, error, tabIndex, focused, 'aria-describedby': ariaDescribedby, intl }) => {
  const { userContextObj } = useUserContext();
  const classes = useStyles();
  const [imgUrl, setImgUrl] = useState();
  const [doctorName, setDoctorName] = useState();

  useEffect(() => {
    // this is needed because name is sometimes an proxy object
    if (typeof name === 'string') {
      setDoctorName(name);
    } else {
      setDoctorName(intl.formatMessage({ id: 'anyDoctor' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  const buttonRef = useRef();
  useEffect(() => {
    if (focused) {
      buttonRef.current.focus();
    }
  }, [focused]);

  useEffect(() => {
    endpoints.user.getImage({ accountId: userContextObj.accountId, userId: id && id !== 'Any' ? id : -1 }).then(({ data }) => setImgUrl(URL.createObjectURL(data)));
  }, [id, userContextObj]);

  const trimmedDescription = description.length > 97 ? description.substring(0, 97) + '...' : description;
  const ariaLabel = (id !== 'Any' ? doctorName : intl.formatMessage({ id: 'anyDoctor' })) + (isSelected ? intl.formatMessage({ id: 'selected' }) : '');

  return (
    <ButtonBase
      tabIndex={tabIndex}
      ref={buttonRef}
      type='button'
      role='radio'
      aria-checked = {isSelected}
      className={classnames(classes.root, { [classes.selected]: isSelected, [classes.error]: error })}
      id={id}
      onClick={() => onSelect({ id, avatar, doctorName, description })}
      aria-label={ariaLabel}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      {...(ariaDescribedby && { 'aria-describedby': ariaDescribedby })}
      focusRipple={false}
    >
      <Avatar
        alt={doctorName}
        aria-hidden={true}
        src={imgUrl}
        className={classes.imageWrapper}
      />
      <p className={classes.Name}>{doctorName}</p>
      <Tooltip title={description}>
        <p className={classes.Description}>{trimmedDescription}</p>
      </Tooltip>
    </ButtonBase>
  );
};

export default injectIntl(ProfessionalCard);
