import React from 'react';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import ErrorAlert from '~/Components/ErrorAlert';
import { ActionButton, FormBuilder } from '~/Components/From';
import { getKey, loadCommonData, loadLocalStorageDataForm, storeInLocalStorage } from '~/Components/From/FormControl/Visitor';
import Loading from '~/Components/Loading';
import EditModal from '~/Components/UI/EditModal';
import { asEntity } from '~/Hoc';
import useStyles from './styles';
import { isEmpty } from '~/Services/Helpers';
import { UserContext } from '~/Services/Providers';
import { fields, formData, formDependency, getFormikProps } from './data';
import StatusField from '../../../../Components/From/StatusField';
import { processFormFields, createFormData, handleSubmit, handleErrors } from '../../../PatientFormUtils';

class NewPatientForm extends React.Component {
  static contextType = UserContext;
  loading = false;
  getLoading = false;
  isSubmitted = false;
  checked = false;
  editValues = { gender: 0 };
  termsOfUse = '';
  privacyPolicy = '';
  showTermsOfUse = false;
  showPrivacyPolicy = false;
  countryDisabled = false;
  pageTitle = '';
  formikProps = null;

  componentWillMount() {
    this.loading = true;
    const { userContextObj } = this.context;
    let isRegisteredDataExist = !isEmpty(getKey('data-form-RegisterForm'));
    const cachedValues = isRegisteredDataExist ? loadCommonData('RegisterForm', fields) : loadLocalStorageDataForm('NewPatientForm');

    this.props.entityStore.get({
      accountId: `${userContextObj.accountId}`,
      accountName: `${userContextObj.accountName}`,
    });

    this.editValues = { gender: 0, ...cachedValues };
  }

  componentDidMount() {
    setTimeout(() => {
      this.pageTitle = <FormattedMessage id='createNewPatientForm' />;
    }, 100);
  }

  handleSubmit = (values) => {
    const result = handleSubmit(
      values,
      this.context,
      this.props.entityStore,
      this.fieldToHide,
      this.termsOfUse,
      this.privacyPolicy,
      this.checked,
      {
        cell: this.context.userContextObj.patients[0].cell,
        email: this.context.userContextObj.patients[0].email,
        forceCreatePatient: true,
      }
    );

    if (result.error) {
      this.error = result.error;
    } else {
      Object.assign(this, result);
    }
  };

  handleErrors = (errors) => {
    this.error = handleErrors(this.checked, this.termsOfUse, this.privacyPolicy);
  };

  entityDidPosted(data) {
    const { userContextObj, setUserContext } = this.context;
    const { accountId, accountName } = userContextObj;
    setUserContext({
      email: userContextObj.patients[0].email,
      cell: userContextObj.patients[0].cell,
      newUserValues: this.values,
    });
    this.props.setScreen(3);
  }

  entityDidReceived(data = {}) {
    if (data) {
      this.privacyPolicy = data.privacyPolicy || null;
      this.termsOfUse = data.termsOfUse || null;

      const { fieldToHide, processedNonRequiredFields } = processFormFields(data);
      this.fieldToHide = fieldToHide;
      this.nonRequiredFields = processedNonRequiredFields;
      this.countryDisabled = data.hideAddressField;

      const { userContextObj } = this.context;
      this.formikProps = getFormikProps(this.nonRequiredFields);
      this.formData = createFormData(
        formData(`Country/list?accountsId=${userContextObj.accountId}`, this.nonRequiredFields),
        this.fieldToHide
      );
    } else {
      this.error = 'somethingError';
    }

    this.loading = false;
  }

  store = (values) => {
    storeInLocalStorage('NewPatientForm', values);
  };

  entityDidCatch(problem) {
    this.loading = false;
    this.error = true;
  }

  changeCheck = () => (this.checked = !this.checked);

  render() {
    const { classes } = this.props;
    const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'));
    let hideMessageAndDataRate = !!fieldsToHide && fieldsToHide?.hideMessageAndDataRate;
    let hideContactInAdvanceText = !!fieldsToHide && fieldsToHide?.hideContactInAdvanceText;

    if (this.getLoading) {
      return <Loading />;
    }

    if (this.formData === undefined) {
      return <Loading />;
    }

    return (
      <>
        <StatusField>{this.pageTitle}</StatusField>
        {this.error && <ErrorAlert messageId={this.error} />}
        <div className={classes.FormWrapper}>
          <div className={classes.header}>
            <div>
              <h1 className={classes.title}>
                <FormattedMessage id='creatPatient' />
              </h1>
            </div>
          </div>
          <div className={classes.FormWrapper}>
            <FormBuilder
              {...this.formikProps}
              formData={this.formData}
              formName='NewPatientForm'
              formDependency={!this.countryDisabled ? formDependency : {}}
              saveInLocalStorage={this.store}
              editValues={this.editValues}
              submitAction={this.handleSubmit}
              errorAction={this.handleErrors}
              customStyle={{ flexBasis: '100%' }}
              trackFirstInteraction={true}
            >
              {(this.termsOfUse || this.privacyPolicy) && (
                <div className={classes.termsPoliciesContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.checked}
                        onChange={this.changeCheck}
                        color='primary'
                      />
                    }
                    label={
                      <>
                        <FormattedMessage id={'policiesNote'} />
                        {this.privacyPolicy && (
                          <a
                            target={'_blank'}
                            href={this.privacyPolicy}
                            className={classes.modalButton}
                            rel="noreferrer"
                          >
                            <FormattedMessage id={'policy'} />
                          </a>
                        )}
                        {this.privacyPolicy && this.termsOfUse && <>{' and '}</>}
                        {this.termsOfUse && (
                          <a
                            target={'_blank'}
                            href={this.termsOfUse}
                            className={classes.modalButton}
                            rel="noreferrer"
                          >
                            <FormattedMessage id={'terms'} />
                          </a>
                        )}
                      </>
                    }
                  />
                </div>
              )}
              <p className={classes.notes}>
                {!hideMessageAndDataRate && (
                  <span style={{ display: 'block' }}>
                    {' '}
                    <FormattedMessage id={'messageApply'} />{' '}
                  </span>
                )}
                {!hideContactInAdvanceText && (
                  <span style={{ display: 'block' }}>
                    {' '}
                    <FormattedMessage id={'contact'} />{' '}
                  </span>
                )}
              </p>
              <div className={classes.actionsWrapper}>
                <ActionButton
                  customClass={classes.SubmitButton}
                  title={'creatPatient'}
                  icon={'event_available_icon'}
                  variant='contained'
                  loading={this.loading}
                />
              </div>
              <EditModal
                open={this.showPrivacyPolicy}
                handleClose={() => (this.showPrivacyPolicy = false)}
              >
                <iframe
                  style={{ width: '100%', height: '100vh' }}
                  src={this.privacyPolicy}
                  title={'decs'}
                />
              </EditModal>
              <EditModal
                open={this.showTermsOfUse}
                handleClose={() => (this.showTermsOfUse = false)}
              >
                <iframe
                  style={{ width: '100%', height: '100vh' }}
                  src={this.termsOfUse}
                  title={'decs'}
                />
              </EditModal>
            </FormBuilder>
          </div>
        </div>
      </>
    );
  }
}

decorate(NewPatientForm, {
  loading: observable,
  isSubmitted: observable,
  error: observable,
  formData: observable,
  privacyPolicy: observable,
  editValues: observable,
  termsOfUse: observable,
  showTermsOfUse: observable,
  showPrivacyPolicy: observable,
  checked: observable,
  countryDisabled: observable,
  pageTitle: observable,
});

export default withStyles(useStyles)(asEntity({ storeId: 'NewPatientForm' })(observer(NewPatientForm)));