import {
  validationSchema as baseValidationSchema,
  getFormikProps as baseGetFormikProps,
  ParseCountryData,
  ParseGenderData,
  formDependency,
} from '../../../PatientFormDataUtils';

export const validationSchema = (nonRequiredFields) => {
  const fields = [
    'firstName',
    'lastName',
    'email',
    'password',
    'passwordConfirm',
    'gender',
    'cell',
    'country',
    'dateOfBirth',
  ];
  return baseValidationSchema(fields, nonRequiredFields);
};

export const getFormikProps = (nonRequiredFields) => {
  const initialVals = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    cell: '',
    phone: '',
    gender: 0,
  };
  return baseGetFormikProps(initialVals, validationSchema(nonRequiredFields));
};

export { ParseCountryData, ParseGenderData, formDependency };

export const formData = (URI1, URI2, nonRequiredFields = []) => {
  const isRequired = (field) => !nonRequiredFields.includes(field);

  return [
    {
      name: 'firstName',
      type: 'firstName',
      width: '48%',
      required: isRequired('firstName'),
    },
    {
      name: 'lastName',
      type: 'lastName',
      width: '48%',
      required: isRequired('lastName'),
    },
    {
      name: 'email',
      type: 'email',
      width: '97.1%',
      message:
        'This information is collected in order to contact you to confirm the creation of online booking profile. Your email address may also be used to confirm your booking',
      info: 'infoEmail',
      required: isRequired('email'),
    },
    {
      name: 'password',
      type: 'password',
      width: '48%',
      required: isRequired('password'),
      enableTooltip: true,
    },
    {
      name: 'passwordConfirm',
      type: 'password',
      width: '48%',
      required: isRequired('passwordConfirm'),
    },
    {
      name: 'cell',
      type: 'phone',
      helperText: 'WeWillSendSMSVerification',
      width: '48%',
      message:
        'This information is collected in order to contact you to confirm your booking',
      info: 'infoCellPhone',
      required: isRequired('cell'),
    },
    {
      name: 'phone',
      type: 'phone',
      width: '48%',
      message:
        'This Information is optional, if you provide this information, it may be used to call you to remind you of your booking',
      info: 'infoHomePhone',
    },
    {
      name: 'gender',
      disableClearIcon: true,
      type: 'dropdown',
      width: '48%',
      optionsURI: '/Patient/gender/list',
      parseData: ParseGenderData,
      required: isRequired('gender'),
    },
    {
      name: 'dateOfBirth',
      type: 'date',
      width: '48%',
      required: isRequired('dateOfBirth'),
    },
    {
      name: 'break',
      type: 'custom',
      content: <div className="customBreak"></div>,
    },
    { name: 'medicalCard', type: 'text', width: '48%' },
    { name: 'medicalCardExp', type: 'date', width: '48%' },
    {
      name: 'break',
      type: 'custom',
      content: <div className="customBreak"></div>,
    },
    { name: 'streetNumber', type: 'text', width: '23%' },
    { name: 'streetName', type: 'text', width: '48%' },
    {
      name: 'unit',
      type: 'text',
      width: '24%',
      message: 'This Information is collected to create your file at the store level',
      info: 'infoUnit',
    },
    { name: 'city', type: 'text', width: '48%' },
    { name: 'postalCode', type: 'text', width: '48%' },
    { name: 'province', type: 'dropdown', width: '48%', options: [] },
    {
      name: 'country',
      type: 'dropdown',
      width: '48%',
      optionsURI: URI1,
      parseData: ParseCountryData,
      required: isRequired('country'),
    },
  ];
};
