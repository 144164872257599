import { Button, withStyles } from '@material-ui/core';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ErrorAlert from '~/Components/ErrorAlert';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { UserContext } from '~/Services/Providers';
import { getAppCountMessage } from '../SelectPatient/Partials/ExistingPatents/data';
import useStyles from './styles';
import ReactGA from 'react-ga4';
import { isEmpty } from '~/Services/Helpers';
import TagManager from 'react-gtm-module';
import { getWink34GA, postMessageScrollUp } from '../../Services/Helpers';
import StatusField from '../../Components/From/StatusField';

const confirmShowExlude = ['firstSelectedDate', 'firstSelectedDateLeadTime', 'firstSelectedAppointmnetSlotsNum', 'selectedTime', 'next7DaysAvailability', 'next14DaysAvailability'];

class ConfirmBooking extends React.Component {
  static contextType = UserContext;
  lastBooking = {};
  error = false;
  appointmentNo = [];
  pageTitle = '';

  handleUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  componentDidMount() {
    postMessageScrollUp();
    const { userContextObj } = this.context;
    localStorage.setItem('booking_stage', 'recap');
    window.addEventListener('beforeunload', this.handleUnload);
    ReactGA.event({
      event: 'page_view',
      vPath: `/book/${localStorage.getItem('store')}/recap`,
      territory: 'en_CA',
      store_type: 'optics',
      booking_stage: 'recap',
      sas_type: 'wink',
      content_id: '/wink/book',
      store_name: localStorage.getItem('store'),
      view_state: !isEmpty(userContextObj.lastBooking) ? 'change' : 'main',
      booking_type: localStorage.getItem('ga_booking_type'),
      dpdItem: 'wink-9',
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        vPath: `/book/${localStorage.getItem('store')}/recap`,
        territory: 'en_CA',
        store_type: 'optics',
        booking_stage: 'recap',
        sas_type: 'wink',
        content_id: '/wink/book',
        store_name: localStorage.getItem('store'),
        view_state: !isEmpty(userContextObj.lastBooking) ? 'change' : 'main',
        booking_type: localStorage.getItem('ga_booking_type'),
        dpdItem: 'wink-9',
      },
    });

    if (Object.keys(userContextObj.lastBooking).length === 0) {
      this.props.history.push('/');
    }
    this.loading = true;
    this.props.entityStore.get({
      accountsId: userContextObj.accountId,
      companyName: userContextObj.accountName,
    });
    this.props.entityStore.get({
      id: userContextObj.selectedPatientId,
    });

    setTimeout(() => {
      this.pageTitle = <FormattedMessage id='bookingInfo' />;
    }, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
  }

  entityDidReceived(data) {
    if (!!data) {
      if (data.code === 1) {
        this.appointmentNo = data.appointment || [];
      } else {
        const ContextLastBooking = this.context.userContextObj.lastBooking;
        let lastBooking = {};
        this.loading = false;
        const stores = data?.stores;
        let storeById = {};
        let doctorById = {};
        let appointmentTypesById = {};
        for (let i = 0; i < stores?.length; ++i) {
          const curr = stores[i];
          storeById[curr.id] = `${curr.name} (${curr.address1} ${curr.address2}, ${curr.city}, ${curr.province})`;
        }
        for (let i = 0; i < data?.appointmentTypes?.length; ++i) {
          const curr = data?.appointmentTypes[i];
          appointmentTypesById[curr.id] = `${curr.name}`;
        }
        for (let i = 0; i < data?.doctors?.length; ++i) {
          const curr = data?.doctors[i];
          doctorById[curr.id] = `${curr.firstName} ${curr.lastName}`;
        }
        lastBooking.storeId = storeById[ContextLastBooking.storeId];
        lastBooking.appointmentTypeId = appointmentTypesById[ContextLastBooking.appointmentTypeId];
        lastBooking.doctorId = doctorById[ContextLastBooking.doctorId];
        lastBooking.date = ContextLastBooking.date;
        lastBooking.timeStart = ContextLastBooking.timeStart;
        lastBooking.timeEnd = ContextLastBooking.timeEnd;
        lastBooking.firstSelectedDate = ContextLastBooking.firstSelectedDate;
        lastBooking.firstSelectedDateLeadTime = ContextLastBooking.firstSelectedDateLeadTime;
        lastBooking.firstSelectedAppointmnetSlotsNum = ContextLastBooking.firstSelectedAppointmnetSlotsNum;
        lastBooking.next7DaysAvailability = ContextLastBooking.next7DaysAvailability;
        lastBooking.next14DaysAvailability = ContextLastBooking.next14DaysAvailability;
        lastBooking.selectedTime = ContextLastBooking.selectedTime;
        this.lastBooking = lastBooking;
      }
    }
  }

  sendGAEventOnSubmit = (action) => {
    const { userContextObj } = this.context;
    const { lastBooking } = userContextObj;
    const wink34GA = getWink34GA(lastBooking, action);

    ReactGA.event(wink34GA);

    TagManager.dataLayer({
      dataLayer: wink34GA,
    });
  };

  //"Any Avilabel Professional"
  handleSubmit = () => {
    this.error = undefined;
    this.loading = true;
    const { userContextObj } = this.context;
    this.props.entityStore.post({
      earlyRequest: false,
      earlyRequestComment: '',
      ...userContextObj.lastBooking,
      patientId: userContextObj.selectedPatientId,
      accountsId: userContextObj.accountId,
      appointmentModification: false,
      oldappointmentId: '',
      forceCreatePatient: false,
      isBookedOnline: true,
    });
    this.sendGAEventOnSubmit('book_now');
  };

  entityDidPosted = (data) => {
    this.loading = false;
    const { setUserContext } = this.context;
    setUserContext({ lastBooking: {} });
    this.props.history.push('/booking-confirmation');
  };

  entityDidCatch(error) {
    this.loading = false;
    if (error == 'maximumReached') {
      this.error = 'maximumReached';
    } else {
      this.error = 'somethingError';
    }
  }

  render() {
    const { classes } = this.props;
    const { userContextObj, setUserContext } = this.context;
    const { themename } = userContextObj;
    const isSepecsavers = themename && themename === 'Specsavers';
    const getPatientInfo = () => userContextObj.patients.find((entry) => entry.patientId == userContextObj.selectedPatientId);
    const Patient = getPatientInfo();

    if (this.loading) return <Loading />;

    return (
      <div>
        <StatusField>{this.pageTitle}</StatusField>
        {this.error && <ErrorAlert messageId={this.error} />}

        <div className={classes.root}>
          <div className={classes.confirmationDetails}>
            <h1 className={`${classes.header} ${classes.title}`}>
              <FormattedMessage id='bookingInfo' />
            </h1>
            <div
              className={classes.BookingInfo}
              tabIndex='0'
            >
              {Object.keys(toJS(this.lastBooking)).map((objKey, index) => {
                if ((!isSepecsavers || objKey !== 'timeEnd') && !confirmShowExlude.find((exc) => exc === objKey) && this.lastBooking[objKey]) {
                  return (
                    <React.Fragment key={index}>
                      <div className={classes.item}>
                        <span className={classes.infoKey}>
                          <FormattedMessage id={`ConfirmBookingScreen.${objKey}`} /> :
                        </span>
                        <span className={classes.infoValue}>&nbsp; {this.lastBooking[objKey]}</span>
                      </div>
                    </React.Fragment>
                  );
                } else return <></>;
              })}
              <div className={classes.item}>
                <span className={classes.infoKey}>
                  <FormattedMessage id={`ConfirmBookingScreen.Patient`} /> :
                </span>
                <span className={classes.infoValue}>
                  &nbsp; {Patient.firstName} {Patient.lastName}
                </span>
              </div>
              <div className={classes.item}>
                <span className={classes.infoKey}>
                  <FormattedMessage id={`ConfirmBookingScreen.appointmentNo`} /> :
                </span>
                <span className={classes.infoValue}>
                  &nbsp;
                  {this.appointmentNo.length > 0 ? this.appointmentNo.length : ''}
                  &nbsp;
                  {<FormattedMessage id={getAppCountMessage(this.appointmentNo.length)} />}
                </span>
              </div>
            </div>
          </div>

          <div className={classes.actionsWrapper}>
            <Button
              variant='contained'
              disabled={this.loading}
              color='primary'
              onClick={() => {
                localStorage.setItem('booking_stage', 'confirmation');
                ReactGA.event({
                  event: 'page_view',
                  vPath: `/book/${localStorage.getItem('store')}/confirmation`,
                  territory: 'en_CA',
                  store_type: 'optics',
                  booking_stage: 'confirmation',
                  sas_type: 'wink',
                  content_id: '/wink/book',
                  store_name: localStorage.getItem('store'),
                  view_state: 'main',
                  booking_type: '',
                  dpdItem: 'wink-11',
                });

                TagManager.dataLayer({
                  dataLayer: {
                    event: 'page_view',
                    vPath: `/book/${localStorage.getItem('store')}/confirmation`,
                    territory: 'en_CA',
                    store_type: 'optics',
                    booking_stage: 'confirmation',
                    sas_type: 'wink',
                    content_id: '/wink/book',
                    store_name: localStorage.getItem('store'),
                    view_state: 'main',
                    booking_type: '',
                    dpdItem: 'wink-11',
                  },
                });
                setUserContext({ lastBooking: {} });

                this.handleSubmit();
              }}
              className={classes.SubmitButton}
              style={{ fontWeight: !isSepecsavers && 'bold' }}
              focusRipple={false}
            >
              <FormattedMessage id='bookNow' />
            </Button>
            <div className={classes.separator}></div>
            <Button
              variant='outlined'
              color='primary'
              disabled={this.loading}
              onClick={() => {
                localStorage.setItem('booking_stage', 'confirmation');
                this.sendGAEventOnSubmit('cancel');
                setUserContext({ lastBooking: {} });
                this.props.history.push('/');
              }}
              className={classes.DangerButton}
              focusRipple={false}
            >
              <FormattedMessage id='cancel' />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
decorate(ConfirmBooking, {
  loading: observable,
  lastBooking: observable,
  error: observable,
  appointmentNo: observable,
  pageTitle: observable,
});

export default withStyles(useStyles)(asEntity({ storeId: 'ConfirmBooking' })(observer(ConfirmBooking)));
