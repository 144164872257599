import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormBuilder, ActionButton } from '~/Components/From';
import { UserContext } from '~/Services/Providers';
import { formData, formikProps, formDependency } from './data';
import useStyles from "~/Screens/GeneralStyles/formStyle";
import { asEntity } from '~/Hoc';
import ErrorAlert from '~/Components/ErrorAlert';
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'

class CancelForm extends React.Component {
    static contextType = UserContext;
    loading = false;

    handleSubmit = (values, editValues = {}) => {
        const { userContextObj } = this.context;
        const { patientId, storeId, appointmentId } = this.props;
        this.loading = true;
        this.error = undefined;
        this.props.entityStore.post({ ...values, accountsId: userContextObj.accountId, appointmentId: appointmentId, patientId: patientId, storeId: storeId, patientId });

    };
    entityDidPosted(data) {
        this.loading = false;
        this.error = undefined;
        localStorage.setItem('booking_stage', 'cancel');
        ReactGA.event({
            event: "page_view",
            vPath: `/book/${localStorage.getItem('store')}/cancel-confirmation`,
            territory: "en_CA",
            store_type: "optics",
            booking_stage: "cancel",
            sas_type: "wink",
            content_id: "/wink/book",
            store_name: localStorage.getItem('store'),
            view_state: "change",
            booking_type: "",
            dpdItem: "wink-21",
        })
        TagManager.dataLayer({
            dataLayer: {
                event: "page_view",
                vPath: `/book/${localStorage.getItem('store')}/cancel-confirmation`,
                territory: "en_CA",
                store_type: "optics",
                booking_stage: "cancel",
                sas_type: "wink",
                content_id: "/wink/book",
                store_name: localStorage.getItem('store'),
                view_state: "change",
                booking_type: "",
                dpdItem: "wink-21",
            }
        })
        this.props.submit()
    }
    entityDidCatch(error) {
        this.error = error && (error.message === 'Appointment cannot be cancelled' ? 'appointmentCancelDisabledMessage' : 'somethingError');
        this.loading = false;

    }

    render() {
        const { classes } = this.props;

        return (
            <>{this.error && <ErrorAlert messageId= {this.error} />}
                <div className={classes.FormWrapper}>
                    <FormBuilder {...formikProps}
                        formData={formData}
                        formName="CancelForm"
                        formDependency={formDependency}
                        editValues={this.values}
                        submitAction={this.handleSubmit}
                        customStyle={{ flexBasis: "100%" }}
                    >
                        <div className={classes.actionsWrapper}>
                            <ActionButton customClass={classes.SubmitButton} title={'Cancel appointment'}
                                variant="contained"
                                loading={this.loading}
                            />
                        </div>
                    </FormBuilder>
                </div >
            </>

        )
    }
}


decorate(CancelForm, {
    loading: observable,
    error: observable
})
export default withStyles(useStyles)(asEntity({ storeId: "CancelForm" })(observer(CancelForm)));
