import formStyles from "../GeneralStyles/formStyle";

const useStyles = (theme) => ({
    ...formStyles(theme),
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        display: "flex",
        flexDirection: "column",

    },
    header: {
        marginTop: theme.spacing(2),
    },
    content: {
        textAlign: "center"
    },
    fullName: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        padding: "5px",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
    },
    info: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '& > div': {
                flexBasis: '100%',
            }
        },
    },
    loading: {
        width: "100%",
        justifyContent: "center",
        display: "flex",
    },
    separator: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(0),
            marginBottom: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
        }
    }

});


export default useStyles;