import { withStyles } from '@material-ui/core';
import BackArrow from '@material-ui/icons/ArrowBackIos';
import ForwardArrow from '@material-ui/icons/ArrowForwardIos';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import uuid from 'react-uuid';
import { asEntity } from '../../../Hoc';
import { isEmpty, onlyOneOfProps } from '../../../Services/Helpers';
import { ProfessionalCard } from './Partials';
import Styles from './styles';
import Loading from '../../../Components/Loading';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { baseGAObject } from '../../../Services/Helpers';
import { UserContext } from '../../../Services/Providers/UserContextProvider';
import { List, ListItem } from '@mui/material';
import StatusField from '../StatusField';

class Slider extends React.Component {
  static contextType = UserContext;
  value = '';
  options = [];
  selected = -1;
  hovered = -1;
  bluring = false;
  focusing = false;
  shouldFocus = false;
  cardJumpOffset = 186;
  loading = false;
  defaultValue = !!this.props.editValue ? this.props.editValue : this.value;
  dataFetchError = false;
  code = uuid();
  appointmentTypes = [];
  appointmentTypeId = this.props.appointmentTypeId;
  required = this.props.required;

  constructor(props) {
    super(props);
    this.CardWrapper = React.createRef();
  }

  componentDidMount = () => {
    if (!!this.props.options) {
      // eslint-disable-next-line eqeqeq
      this.defaultValue = !isEmpty(this.props.editValue) ? this.props.options.find((element) => element.id == this.props.editValue) : this.value;

      this.options = this.props.options;

      this.value = this.defaultValue;
      this.selected = this.defaultValue.id;
      this.shouldFocus = this.props.focused;
      this.props.InputChange(this.props.name, this.defaultValue?.id); //this.defaultValue.id
    } else {
      this.loading = true;
      if (!isEmpty(this.props.optionsURI)) {
        this.props.entityStore.get({ uri: this.props.optionsURI, body: {}, code: this.code });
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.optionsURI !== prevProps.optionsURI) {
      this.loading = true;
      this.props.entityStore.get({ uri: this.props.optionsURI, body: {}, code: this.code });
    }
    if (this.props.options !== prevProps.options) {
      this.options = this.props.options;
      this.value = this.defaultValue;
      this.selected = this.defaultValue.id;
      this.props.InputChange(this.props.name, this.defaultValue?.id); //this.defaultValue.id
    }
    if (this.props.appointmentTypeId !== prevProps.appointmentTypeId) {
      this.appointmentTypeId = this.props.appointmentTypeId;
    }
    if (prevProps.focused !== this.props.focused) {
      this.shouldFocus = this.props.focused;
    }
  }

  handleProfCardSelect = (value) => {
    this.context.setUserContext({ interactedField: { name: this.props.name } });
    this.value = isEmpty(value) ? '' : value;
    this.selected = isEmpty(value) ? -1 : value.id;
    let formikValue = isEmpty(value) ? '' : value.id; //value.id
    this.props.InputChange(this.props.name, formikValue);
    // eslint-disable-next-line eqeqeq
    let ecpName = this.value.id == 'Any' ? 'Any' : this.value.name;
    ReactGA.event({
      ...baseGAObject,
      ...{
        event: 'button_click',
        content_id: '/wink/book',
        click: `${ecpName}_${localStorage.getItem('booking_stage')}`,
        territory: 'en_CA',
        store_type: 'optics',
        sas_type: 'wink',
        booking_type: localStorage.getItem('ga_booking_type'),
        booking_stage: localStorage.getItem('booking_stage'),
        store_name: localStorage.getItem('store'),
        view_state: 'main',
        dpdItem: 'wink-22',
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        ...baseGAObject,
        ...{
          event: 'button_click',
          content_id: '/wink/book',
          click: `${ecpName}_${localStorage.getItem('booking_stage')}`,
          territory: 'en_CA',
          store_type: 'optics',
          sas_type: 'wink',
          booking_type: localStorage.getItem('ga_booking_type'),
          booking_stage: localStorage.getItem('booking_stage'),
          store_name: localStorage.getItem('store'),
          view_state: 'main',
          dpdItem: 'wink-22',
        },
      },
    });

    if (this.props.onSelection) {
      this.props.onSelection();
    }
  };

  handleProfCardHoverAdjacent = (idRef, jump) => {
    const indRef = this.options.findIndex((opt) => opt.id === idRef);
    if (indRef > -1 && this.options && this.options.length > 0) {
      const newInd = indRef + jump;
      if (newInd > -1 && newInd < this.options.length) {
        this.hovered = this.options[newInd].id;
        this.CardWrapperScroll(newInd);
      }
    }
  };

  entityDidReceived(data) {
    if (!!data && this.code === data.code) {
      if (this.props.parseData) {
        this.options = this.props.parseData(data.content);
      } else {
        this.options = data.content;
      }
      const length = toJS(this.options).length;
      for (let i = 0; i < length; i++) {
        if (this.options[i].id === this.props.editValue) {
          this.defaultValue = toJS(this.options)[i];
        }
      }
      this.loading = false;
      this.props.InputChange(this.props.name, this.defaultValue.id);
      this.value = this.defaultValue;
      this.selected = this.defaultValue.id;
    }
  }

  entityDidCatch(error) {
    if (error.code === this.code) {
      this.dataFetchError = true;
    }
  }

  CardWrapperScroll = (jump, absolute = true) => {
    this.CardWrapper.current.scrollLeft = jump * this.cardJumpOffset + (absolute ? 0 : this.CardWrapper.current.scrollLeft);
  };

  getShouldRenderArrow = (windowWidth) => {
    return this.CardWrapper && this.CardWrapper.current && this.CardWrapper.current.getBoundingClientRect().width < this.options.length * this.cardJumpOffset;
  };

  handleFocus = (e, id) => {
    // if (this.hovered < 0 && this.options && this.options.length > 0) {
    //   this.hovered = this.selected > -1 ? this.selected : this.options[0].id;
    // }
    this.hovered = id;
    this.bluring = false;
    if (!this.options.find((opt) => e.relatedTarget && e.relatedTarget.id && opt.id && opt.id + '' === e.relatedTarget.id)) {
      this.focusing = true;
    }
  };

  handleBlur = (e) => {
    this.hovered = -1;
    this.shouldFocus = false;
    this.focusing = false;
    if (!this.options.find((opt) => e.relatedTarget && e.relatedTarget.id && opt.id && opt.id + '' === e.relatedTarget.id)) {
      this.bluring = true;
      this.props.blur({ target: { name: this.props.name } });
    }
  };

  handleComponentKeyDown = (e, id) => {
    switch (e.code) {
      case 'Enter':
        if (this.options && this.options.length > 0) {
          const sel = this.options.find((opt) => opt.id === this.hovered);
          this.handleProfCardSelect(sel);
        }
        break;
      case 'ArrowLeft':
        this.handleProfCardHoverAdjacent(id, -1);
        break;
      case 'ArrowRight':
        this.handleProfCardHoverAdjacent(id, 1);
        break;
      default:
        break;
    }
  };

  renderExamTypeDescText = () => {
    const selectedAppointTypeId = this.props.appointmentTypeId;
    const { themename, appointmentInfoData } = this.context.userContextObj;

    // Getting Appointment Type Name
    const appointmentTypeName = appointmentInfoData?.appointmentTypes?.find((el) => el?.id === selectedAppointTypeId)?.name ?? '';
    const isLoblaw = themename && themename === 'Loblaw';
    const isAppointmentFullEyeExam = appointmentTypeName && appointmentTypeName?.toLowerCase()?.includes('full eye exam');
    if (isLoblaw && isAppointmentFullEyeExam) {
      return (
        <span className={this.props.classes.sliderDescriptionText}>
          <FormattedMessage id={`doctorSliderDescriptionText`} />
        </span>
      );
    } else {
      return null;
    }
  };

  getRequiredText = () => {
    let { classes } = this.props;

    return this.required ? <span className={classes.requiredAsterisk}>*</span> : null;
  };

  getPreviousOptionsAriaLabel = () => {
    const { formatMessage } = this.props.intl;
    const label = formatMessage({ id: 'previousOptions' });
    return label;
  }

  getNextOptionsAriaLabel = () => {
    const { formatMessage } = this.props.intl;
    const label = formatMessage({ id: 'nextOptions' });
    return label;
  }

  getAriaLabel = () => {
    // if required, add required to the label, else just the label
    const { formatMessage } = this.props.intl;
    const ecp = formatMessage({ id: this.props.label });
    const requiredText = formatMessage({ id: 'field.required' });
    return this.required ? `${ecp} (${requiredText})` : ecp;
  };

  render() {
    let { error, errorText, label, classes, show } = this.props;
    let windowWidth = window.innerWidth;
    let shouldRenderArrow = this.getShouldRenderArrow(windowWidth);
    if (this.loading) {
      return (
        <div style={{ width: '100%' }}>
          <Loading />
        </div>
      );
    }
    return (
      <>
        {show && (
          <div className={classes.CardsRootWrapper}>
            <StatusField>{this.focusing && <FormattedMessage id={label} />}</StatusField>
            <h2
              className={`${classes.sliderTitle} ${classes.title}`}
              id="combined-heading"
              aria-label={this.getAriaLabel()}
            >
              <FormattedMessage id={label} />{' '}
              {this.getRequiredText()}
            </h2>
            {this.renderExamTypeDescText()}
            <div className={classes.root}>
              {shouldRenderArrow && (
                <button
                  onClick={() => this.CardWrapperScroll(-1, false)}
                  className={classes.Prev}
                  aria-label={this.getPreviousOptionsAriaLabel()}
                >
                  <BackArrow />
                </button>
              )}

              <List
                className={classes.cardList}
                ref={this.CardWrapper}
                role='radiogroup'
                aria-labelledby="combined-heading"
                aria-describedby={error ? "slider-helper" : undefined}
              >
                {this.options.length > 0 &&
                  this.options.map((option, index) => (
                    <ListItem
                      key={'slider-item-' + index}
                      className={classes.cardItem}
                    >
                      <ProfessionalCard
                        onSelect={this.handleProfCardSelect}
                        onFocus={e => this.handleFocus(e, option.id)}
                        onBlur={this.handleBlur}
                        tabIndex={this.hovered > -1 ? (option.id === this.hovered ? 0 : -1) : index === 0 ? 0 : -1}
                        isSelected={this.selected === option.id}
                        focused={this.shouldFocus ? index === 0 : option.id === this.hovered}
                        onKeyDown={e => this.handleComponentKeyDown(e, option.id)}
                        error={error}
                        {...(error && (this.bluring || this.focusing) && { 'aria-describedby': 'slider-helper' })}
                        {...option}
                      />
                    </ListItem>
                  ))}
              </List>

              {shouldRenderArrow && (
                <button
                  onClick={() => this.CardWrapperScroll(1, false)}
                  className={classes.Next}
                  aria-label={this.getNextOptionsAriaLabel()}
                >
                  <ForwardArrow />
                </button>
              )}
            </div>
            {error && (
              <div
                className={classes.error}
                id='slider-helper'
              >
                <FormattedMessage id={errorText} />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

const onlyOneOfPropsData = {
  options: 'object',
  optionsURI: 'string',
};

Slider.propTypes = {
  options: onlyOneOfProps(onlyOneOfPropsData),
  optionsURI: onlyOneOfProps(onlyOneOfPropsData),
};

decorate(Slider, {
  options: observable,
  dataFetchError: observable,
  selected: observable,
  hovered: observable,
  appointmentTypeId: observable,
  shouldFocus: observable,
  bluring: observable,
  focusing: observable,
});

export default injectIntl(withStyles(Styles)(asEntity({ storeId: 'GenericDropdown' })(observer(Slider))));
