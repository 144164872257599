/* eslint-disable import/no-anonymous-default-export */
import { put, call } from "redux-saga/effects";
import api from "../../Services/Api";
import { StorageKeys } from "~/Configs/Storage";
import EntityActions from "../Actions/Entity";
import { getStorage, setStorage } from "~/Services/Helpers";
import { extractMessage } from "~/Services/Helpers";
import { isEmpty } from '~/Services/Helpers';

export default {

  *get({ id, data }) {
    const token = getStorage(StorageKeys.token);
    try {
      if (id === 'GenericDropdown') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.dynamicComponent.get, data, { headers: { Authorization: token , 
          "Accept-Language" : localStorage.getItem('locale')
        }});
        if (res.ok || res.status===304) { 
          yield put(EntityActions.getSucceeded(id, { content: res.data, uri: data.uri, code: data.code }));
        } else {
          yield put(EntityActions.getFailed(id, { code: data.code, message: extractMessage(res.data) }));
        }
      }
      if (id === 'BookForm') {
        const res = yield call(api.booking.get, data, {});
        if (res.ok || res.status===304) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, res.data));
        }
      }
      if (id === 'RegisterForm') {
        const res = yield call(api.getInfo.get, data, {});
        if (res.ok || res.status===304) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, res.data));
        }
      }
      if (id === 'SelectPatient') {
        if (token !== "undefined") {
          let responses = []
          let ok = true
          for (let i = 0; i < data.array.length; ++i) {
            const res = yield call(api.appointments.get, {
              id: `${data.array[i]?.patientId}`,
              accountsId: data.accountId,
              companyName: data.accountName,
            }, { headers: { Token: token } });
            if (Array.isArray(res.data.appointment)) { responses.push(res.data.appointment.length) }
            else responses.push(0)
            ok &= res.ok;
          }
          if (ok) {
            yield put(EntityActions.getSucceeded(id, responses));
          } else {
            yield put(EntityActions.getFailed(id, responses.data));
          }
        } else yield put(EntityActions.getSucceeded(id, []))
      }
      if (id === 'Appointments') {
        const res = yield call(api.appointments.get, data, { headers: { Token: token } });
        const res1 = yield call(api.booking.get, data, {});
        if (res.ok || res.status===304) {
          yield put(EntityActions.getSucceeded(id, { appointments: res.data, stores: res1.data }));
        } else {
          yield put(EntityActions.getFailed(id, res.data));
        }
      }
      if (id === 'BookForm') {
        const res = yield call(api.storeLogo.get, data, {});
        if (res.ok || res.status===304) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, res.data));
        }
      }


      if (id === 'UserProfile') {
        const res = yield call(api.patient.patientById, data, { headers: { Token: token } });
        const res1 = yield call(api.gender.get, data, { headers: { Token: token } });
        if (res.ok && res1.ok) {
          
          yield put(EntityActions.getSucceeded(id,{ patientInfo : res.data , genders : res1.data}));
        } else {
          yield put(EntityActions.getFailed(id, res.data));
        }
      }
      if (id === 'Layout') {
        const res = yield call(api.getInfo.get, data, {});
        if (res.ok || res.status===304) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, res.data));
        }
      }
      if (id === 'ConfirmBooking') {
        let res = "";
        if (data.id) {
          res = yield call(api.appointments.get, data, { headers: { Token: token } });
        } else {
          res = yield call(api.booking.get, data, { headers: { Token: token } });
        }
        if (res.ok || res.status===304) {
          if (data.id)
            yield put(EntityActions.getSucceeded(id, { ...res.data, code: 1 }));
          else yield put(EntityActions.getSucceeded(id, { ...res.data }));

        } else {
          yield put(EntityActions.getFailed(id, res.data));
        }
      }
      if (id === 'LoginForm') {
        const res = yield call(api.patient.get, data, { headers: { token: token } });
        if (res.ok || res.status===304) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, res.data));
        }
      }
      if (id === 'DynamicInput') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.GenericHandlers.get, data, { headers: { Authorization: token } });
          if (res.ok || res.status===304) {
            yield put(EntityActions.getSucceeded(id, { content: res.data.content, uri: data.uri, code: data.code, parentID: data.parentID }));
          } else {
            yield put(EntityActions.getFailed(id, { code: data.code, message: extractMessage(res.data) }));
          }
        }
      }
      if (id === 'GenericDropdownMultiple') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.GenericHandlers.get, data, {
          headers: {
            Authorization: token,
          },
        });
        if (res.ok || res.status===304) {
          yield put(EntityActions.getSucceeded(id, { ...res.data, uri: data.uri, code: data.code }));
        } else {

          yield put(EntityActions.getFailed(id, { code: data.code, message: extractMessage(res.data) }));
        }
      }
      if (id === 'NewPatientForm') {
        let res = yield call(api.getInfo.get, data, {});
        if (res.ok || res.status===304) {
          yield put(EntityActions.getSucceeded(id, res.data));

        } else {
          yield put(EntityActions.getFailed(id, res.data));
        }
      }
    } catch (errors) {
      yield put(EntityActions.getFailed(id, "Connetion Error"));
    }
  },
  *post({ id, data, name }) {
    const token = getStorage(StorageKeys.token);
    try {
      if (id === 'LoginForm') {
        const res = yield call(api.account.login, data);
        if (res.ok && res?.data?.patient?.length > 0) {
          const token = res?.headers?.token
          setStorage(StorageKeys.token, token);
          yield put(EntityActions.postSucceeded(id, { ...res.data, token: token }));
        } else {
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
      if (id === 'SelectPatient') {
        const res = yield call(api.account.login, data);
        if (res.ok && res?.data?.patient?.length > 0) {
          const token = res?.headers?.token
          setStorage(StorageKeys.token, token);
          yield put(EntityActions.postSucceeded(id, { ...res.data, token: token }));
        } else {
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
      if (id === 'RegisterForm') {
        const res = yield call(api.account.sendToken, data);
        const isInvalid = res?.data?.message == "InvalidPassword" || res?.data?.patientloginExist;
        if (res.ok && !isInvalid) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
      if (id === 'AddNewPassword') {
        const res = yield call(api.account.resetPassword, data);
        if (res.ok || res.status===304) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
      if (id === 'TokenBeforeAppointment') {
        if (!data.resend) {
          const res = yield call(api.Profile.post, data.payload);
          if (res.ok || res.status===304) {
            const token = res?.headers?.token
            yield put(EntityActions.postSucceeded(id, { ...res.data, token }));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
        else {
          const res = yield call(api.account.sendToken, data.payload);
          if (res.ok || res.status===304) {
            yield put(EntityActions.postSucceeded(id, { resend: true }));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }

      if (id === 'ConfirmPIN') {
        if (!data.resend) {
          const res = yield call(api.Profile.post, data.payload);
          if (res.ok || res.status===304) {
            const token = res?.headers?.token
            yield put(EntityActions.postSucceeded(id, { ...res.data, token }));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        } else {
          const res = yield call(api.account.sendToken, data.payload);
          if (res.ok || res.status===304) {
            yield put(EntityActions.postSucceeded(id, { resend: true }));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }

      if (id === 'resetPasswordSMSForm' || id === 'resetPasswordEmailForm') {
        const res = yield call(api.account.ForgotPassword, data);
        if (res.ok && (!isEmpty(res?.data?.smsSent) || !isEmpty(res?.data?.emailSent))) {
          yield put(EntityActions.postSucceeded(id, res));
        } else {
          yield put(EntityActions.postFailed(id, "smsPinCodeError"));
        }
      }
      if (id === 'EmailConfirm') {
        const res = yield call(api.confirmLoginToken.post, data);
        if (res.ok && res?.data?.message === "success") {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
      if (id === 'NewPassword') {
        let message = '';
        let { type, payload } = data;
        let res = {};
        if (type === 'resetPassword') {
          res = yield call(api.account.resetPassword, payload);
          message = res?.data?.message;
        } else {
          res = yield call(api.confirmLoginToken.post, payload);
          message = res?.data?.message
        }
        
        if (res.ok && message === "success") {
          yield put(EntityActions.postSucceeded(id, { type, payload: res.data }));
        } else {
          yield put(EntityActions.postFailed(id, {type , data : res.data}));
        }
      }
      if (id === 'verificationCodeForm') {
        if (isEmpty(data.resend)) {
          let res = "";
          if (data.smsPinCodeResetPassword) {
            res = yield call(api.confirmResetPassword.post, data);
          } else {
            res = yield call(api.account.resetPassword, data);
          }
          if (res.ok && res?.data?.message === "success") {
            yield put(EntityActions.postSucceeded(id, res));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        } else {
          const res = yield call(api.account.ForgotPassword, data.data);
          if (res.ok && res?.data?.smsSent) {
            yield put(EntityActions.postSucceeded(id, res));
          } else if (res.ok && res?.data?.errors) {
            yield put(EntityActions.postFailed(id, "errorOccured"));
          } else {
            yield put(EntityActions.postFailed(id, "smsPinCodeError"));
          }
        }
      }
      if (id === 'ConfirmBooking') {
        const res = yield call(api.booking.post, data, { headers: { Token: token } });
        if (res.ok && !res.data.maxBookedAppointmentReached) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          if (res?.data.maxBookedAppointmentReached) {
            yield put(EntityActions.postFailed(id, 'maximumReached'));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === 'CancelForm') {
        const res = yield call(api.booking.cancel, data, { headers: { Token: token } });
        if (res.ok || res.status===304) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
      if (id === 'Appointments') {
        const res = yield call(api.account.sendToken, data, { headers: { Token: token } });
        if (res.ok || res.status===304) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
      if (id === 'AppointmentForm') {
        const res = yield call(api.booking.post, data, { headers: { Token: token } });
        if (res.ok || res.status===304) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
      if (id === 'NewPatientForm') {
        const res = yield call(api.account.sendToken, data, { headers: { Token: token } });
        if (res.ok || res.status===304) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
    } catch (errors) {
      yield put(EntityActions.postFailed(id, "Connection Error"));
    }
  },
  *put({ id, data }) {
    if (id === "InlineInput") {
      const token = getStorage(StorageKeys.token);
      const res = yield call(api.GenericHandlers.put, data, { headers: { Authorization: token } });
      if (res.ok || res.status===304) {
        yield put(EntityActions.patchSucceeded(id, res.data));
      } else {
        yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
      }
    }
  },
  *patch({ id, data }) {
    try {
    } catch (errors) {
      yield put(EntityActions.patchFailed(id, "Connection Error"));
    }

  },
  *delete({ id, data }) {
    try {
    }
    catch (ex) {
      yield put(EntityActions.deleteFailed(id, "Connection Error"));
    }
  }
}
