import { Button, withStyles } from "@material-ui/core";
import { decorate, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import ErrorAlert from "~/Components/ErrorAlert";
import { ActionButton, FormBuilder } from "~/Components/From";
import { asEntity } from "~/Hoc";
import useStyles from "./style";
import { formData, formDependency, formikProps } from "./data";
import { StorageKeys } from "~/Configs/Storage";
import { getStorage, setStorage } from "~/Services/Helpers";
import SuccessAlert from "~/Components/SuccessAlert";
import StatusField from "../../../../../../Components/From/StatusField";
class verificationCodeForm extends React.Component {
  loading = false;
  resend = false;
  getLoading = false;
  isSuccessfullyResended = false;
  isReseted = false;
  values = {};
  user = {};
  token = "";
  pageTitle = '';
  componentWillMount() {
    this.props.removeHeader();
    this.user = JSON.parse(getStorage(StorageKeys.userContext));
  }

  componentDidMount() {
    setTimeout(() => {
      this.pageTitle = (
        <div>
          <FormattedMessage id={'VerificationCode'} />
          {'! '}
          <FormattedMessage id={'VerificationCodeExample1'} />
          {this.user && this.user.cell && this.user.cell }
          <FormattedMessage id={'VerificationCodeExample2'} />
          {'! '}
          <FormattedMessage id={'VerificationCodeExample3'} />
        </div>
      );
    }, 1000);
  }

  Resend = () => {
    this.resend = true;
    this.loading = true;
    this.error = false;
    this.props.entityStore.post({
      resend: true,
      data: this.user.resend,
    });
  };

  handleSubmit = (values, editValues = {}) => {
    this.error = undefined;
    this.loading = true;
    this.props.entityStore.post({
      accountsId: this.user.accountId,
      smsPinCodeResetPassword: values.token,
      cell: this.user.cell,
    });
    this.token = values.token;
  };

  entityDidPosted(data) {
    if (!!data) {
      if (data.message == "InvalidPassword") {
        this.error = "invalidPassword";
      } else if (!this.resend) {
        this.loading = false;
        this.isReseted = true;
        setStorage(StorageKeys.resetPasswordToken, this.token);
        this.props.moveForward();
      } else {
        this.isSuccessfullyResended = true;
        this.loading = false;
        this.resend = false;
      }
    }
  }
  entityDidCatch(error) {
    this.loading = false;
    if (error.message == "InvalidPassword") this.error = "invalidPassword";
    else if (error.message == "error") {
      this.error = "smsPinCodeError";
    } else this.error = "error";
    this.isSuccessfullyResended = false;
  }

  render() {
    const { classes } = this.props;

    return (
      <>
      <StatusField>{this.pageTitle}</StatusField>
        {this.error  && <ErrorAlert messageId={this.error} />}
        {this.isSuccessfullyResended && <SuccessAlert type={"success"} messageId="resendInfo" />}
        <div className={classes.FormWrapper}>
          <div className={classes.headerText}>
            <h3>
              <FormattedMessage id={"VerificationCode"} />
            </h3>
            <p>
              <FormattedMessage id={"VerificationCodeExample1"} />
              {this.user && this.user.cell && ` <${this.user.cell}> `}
              <FormattedMessage id={"VerificationCodeExample2"} />
            </p>
            <p>
              <FormattedMessage id={"VerificationCodeExample3"} />
            </p>
          </div>
          <FormBuilder
            {...formikProps}
            formData={formData}
            formName="verificationCodeForm"
            formDependency={formDependency}
            editValues={this.values}
            submitAction={this.handleSubmit}
            customStyle={{ flexBasis: "100%" }}
          >
            <div className={classes.actionsWrapper}>
              <Button variant="contained" color="primary" onClick={this.Resend} className={classes.SubmitButton} focusRipple={false}>
                <FormattedMessage id="Resend" />
              </Button>
              <ActionButton
                customClass={classes.SubmitButton}
                title={"ResetPassword"}
                icon={"settings_backup_restore"}
                variant="contained"
                loading={this.loading}
              />
            </div>
          </FormBuilder>
        </div>
      </>
    );
  }
}

decorate(verificationCodeForm, {
  loading: observable,
  resend: observable,
  error: observable,
  isSuccessfullyResended: observable,
  pageTitle: observable,
});

export default withStyles(useStyles)(asEntity({ storeId: "verificationCodeForm" })(observer(verificationCodeForm)));
