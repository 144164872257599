import React , { useEffect} from 'react'
import { ReactComponent as ErrorPhoto } from '~/Assets/error.svg';
import useStyles from './styles';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module'

const AppCrashedComponent = () => {
    const classes = useStyles();

    useEffect(() => {
        ReactGA.event({
            event: "page_view",
            vPath: "/book/error",
            territory: "en_CA",
            store_type: "optics",
            booking_stage: "appointment_type",
            sas_type: "wink",
            content_id: "/wink/book",
            view_state: "",
            booking_type: "",
            dpdItem: "wink-23",
        })

        TagManager.dataLayer({
            dataLayer: {
                event: "page_view",
                vPath: "/book/error",
                territory: "en_CA",
                store_type: "optics",
                booking_stage: "appointment_type",
                sas_type: "wink",
                content_id: "/wink/book",
                view_state: "",
                booking_type: "",
                dpdItem: "wink-23",
           }
       })
    }, [])

    return (
        <div className={classes.root}>
            <ErrorPhoto height="50%" width="50%" />
            <h2>Oops !! ... Something went wrong</h2>
            <span variant="outlined" color="primary"
            >
                Please contact your admin.
            </span>
        </div>
    )
}

export default AppCrashedComponent
