import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useStyles } from './styles';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Error } from '~/Assets/error-message.svg';

function ErrorAlert(props) {
  const { classes, messageId, link, onClickEvent, type="error" } = props;
  return (<div className={classes.AlertWrapper}>
    <div className={classes.SVGWrapper}>
      <Error className={classes.SVG} />
    </div>
    <div className={classes.Alert} severity={type}>
      <p style={{ fontWeight: 600, whiteSpace:'pre-line' }} role='alert'> <FormattedMessage id={messageId} /> &nbsp;
        {/* //FIXME remove the inline styles in here */}
        { link  && <a onClick={onClickEvent} style={{ color: 'blue', textDecoration: 'underlined', cursor: 'pointer' }} tabIndex='0'>
            {link}
          </a>
        }
      </p>
    </div>
  </div>);
}

ErrorAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  messageId: PropTypes.string.isRequired,
};


export default (withStyles(useStyles)(ErrorAlert));
