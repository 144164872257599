const useStyles = (theme) => ({
  root: {
    minWidth: 200,
    flex: 1,
    textAlign: 'left',
    marginTop: theme.spacing(1),
    border: 'solid 3px #ebebeb',
    borderRadius: 10,
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  placeholder: {
    color: theme.palette.primary.main,
    marginLeft: `${theme.spacing(2)}px`,
  },
  textareaControl: {
    width: '100%',
    margin: `${theme.spacing(2)}px`,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  calenderHeader: {
    paddingLeft: 14,
    borderBottom: 'solid 4px #ebebeb',
    fontSize: 20,
    paddingBottom: 14,
  },
  error: {
    color: theme.palette.error.main,
    height: 20,
    alignSelf: 'flex-start',
    margin: 10,
  },
  title: {
    fontSize: '0.8em',
    textAlign: 'left',
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
  datePickerWrapperBorder: {
    border: `solid 2px ${theme.palette.primary.main} !important`,
  },
  datePickerWrapper: {
    flex: 1,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 8,
    border: `solid 2px transparent`,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },

    '& .css-epd502': {
      flexGrow: 1,
    },
    '& .MuiDateCalendar-root': {
      flex: 1,
      maxWidth: 9999,
      width: 'auto',
      margin: 0,
    },
    '& .MuiDayCalendar-weekContainer': {
      justifyContent: 'space-between',
      '& .Mui-selected.Mui-focusVisible': {
        border: `4px solid ${theme.palette.primary.main}`,
        backgroundColor: `${theme.palette.grey[200]} !important`,
        color: theme.palette.common.black,
      },
    },
    '& .MuiDayCalendar-header': {
      justifyContent: 'space-between',
    },

    '& .MuiPickersDay-root': {
      fontWeight: 'bold !important',
      '&:hover, &:focus': {
        '&:not(.Mui-selected)': {
          backgroundColor: `${theme.palette.primary.transparent} !important`,
        },
      },
      '&:disabled': {
        color: `${theme.palette.disabled.main}  !important`,
        fontWeight: 'normal !important',
      },
    },
    '& .MuiIconButton-root':{
      '&:hover':{
        backgroundColor: `${theme.palette.primary.transparent} !important`,
      },
    },
    '& .MuiPickersYear-yearButton': {
      '&:focus': {
        border: `2px solid ${theme.palette.border.button}`,
        '&:not(.Mui-selected)': {
          backgroundColor: `${theme.palette.primary.transparent} !important`,
        },
      },
      '&:hover': {
        border: `2px solid ${theme.palette.border.button}`,
        '&:not(.Mui-selected)': {
          backgroundColor: `${theme.palette.primary.transparent} !important`,
        },
      },
    },
    '& .Mui-focusVisible': {
      backgroundColor: `${theme.palette.primary.transparent} !important`,
    },
    '& .Mui-selected': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
    },
  },
  chooseDateWrapperBorder: {
    border: `solid 2px ${theme.palette.primary.main} !important`,
  },
  chooseDateWrapper: {
    flex: 1,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: 8,
    border: `solid 2px transparent`,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 400,
      marginLeft: 0,
      marginRight: 0,
    },
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  dummySpace: {
    flex: 0.2,
  },
  chooseDate: {
    overflow: 'auto',
    maxHeight: 320,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  requiredAsterisk: {
    marginLeft: '0.2em',
    fontWeight: '400',
  },
  requiredText: {
    fontWeight: '300',
    fontStyle: 'italic',
    fontSize: '0.9rem',
    marginLeft: '0.3em',
  }
});

export default useStyles;
