import { lighten } from '@material-ui/core/styles';
import formStyle from '../../../Screens/GeneralStyles/formStyle';

const navigateButtonStyle = (theme) => ({
  width: '30px',
  // margin:"10px",
  height: '220px',
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  // margin: `0px ${theme.spacing(1)}px`,
  margin: `0px ${theme.spacing(0.4)}px`,
  transition: 'all 400ms ease',
  // color: theme.palette.primary.main,
  background: lighten(theme.palette.primary.main, 0.5),
  color: 'white !important',
  '&:hover': {
    background: lighten(theme.palette.primary.main, 0.2),
    color: 'white !important',
  },
});

const Styles = (theme) => ({
  ...formStyle(theme),
  CardsRootWrapper: {
    // position: "relative",
    width: '100%',
    maxWidth: '95vw',
    overflow: 'hidden',
    '&:focus': {
      outline: 'None',
      // border: `1px solid ${theme.palette.text.primary}`,
      // borderRadius: '5px',
    },
  },
  error: {
    color: 'rgb(244, 67, 54)',
    margin: 5,
    height: 20,
    padding: '0px 1em',
    position: 'relative',
    top: '-1em',
  },
  root: {
    display: 'flex',
    width: '100%',
    minHeight: '250px',
    overflow: 'hidden',
  },
  cardList: {
    display: 'flex',
    flexBasis: '100%',
    overflow: 'auto',
    marginHorizontal: '20px',
    scrollBehavior: 'smooth',
    transition: 'all 400ms ease',
    marginLeft: '10px',
    marginRight: '10px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  cardItem: {
    margin: `0 ${theme.spacing(1)}px !important`,
    alignItems: 'normal !important',
    padding: `0 !important`,
    height: 220,
    flex: '0 0 170px',
    boxSizing: 'border-box',
  },
  text: {
    fontSize: '1.1em',
  },
  Next: {
    ...navigateButtonStyle(theme),
  },
  Prev: {
    ...navigateButtonStyle(theme),
    justifyContent: 'flex-end',
  },
  sliderTitle: {
    padding: '0.5em 1em',
  },
  sliderDescriptionText: {
    padding: '0.5em 1em',
    fontSize: '0.8em',
    fontStyle: 'italic',
  },
  requiredAsterisk: {
    fontWeight: '400',
  },
  requiredText: {
    fontWeight: '300 !important',
    fontStyle: 'italic !important',
    fontSize: '0.9rem !important',
    marginLeft: '0.3em !important',
  }
});

export default Styles;
