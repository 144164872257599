import React, { Component } from 'react';
import get from "lodash/get";
import ResetPasswordEmailForm from './Partials/ResetPasswordForm';
import ResetPasswordSMSForm from './Partials/ResetPasswordSMSForm';
import VerificationCodeForm from './Partials/VerificationCodeForm';
import { withStyles } from '@material-ui/core';
import useStyles from "./styles";
import TabPanel from '../TabPanel';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { FormattedMessage } from 'react-intl'
import AddNewPassword from './Partials/AddNewPassword';

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            showHeader: true,
            value: 0,
            steps: [[
                {
                    component: <ResetPasswordSMSForm moveForward={this.moveForward} />,
                },
                {
                    component: <VerificationCodeForm
                        userContextObj={this.props.userContextObj}
                        removeHeader={() => this.setState({ showHeader: false, })}
                        moveForward={this.moveForward} />
                },
                {
                    component: <AddNewPassword
                        userContextObj={this.props.userContextObj}
                        removeHeader={() => this.setState({ showHeader: false, })}
                        moveForward={this.moveFinish} />
                },
            ],
            [
                {
                    component: <ResetPasswordEmailForm moveForward={this.moveFinish} />,
                },
            ]
            ],
            data: {},
        };
        this.props.setUserContext({ backHandler: () => this.props.setValue(1) })

    }
    moveForward = (data = {}) => {
        this.setState({ activeStep: this.state.activeStep + 1, data: { ...data } });
    }
    moveFinish = (data = {}) => {
        this.setState({ data: { ...data } });
        this.props.setValue(1, 'resetPasswordSuccessMessage', 'success');
    }
    get activeStep() {
        const { activeStep, value } = this.state;
        const step = get(this.state, `steps.[${value}].${activeStep}`, {});
        return step;
    }

    changeHandler = (value) => {
        this.setState({ value: value, });
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {this.state.showHeader && <div className={classes.header}>
                    <h1 className={classes.title}><FormattedMessage id="ResetYourPassword" /></h1>
                    <div className={classes.buttonGroup}>
                        <ButtonGroup color="primary" >
                            <Button className={classes.navigationButton} variant={this.state.value === 0 ? "contained" : "outlined"} onClick={() => this.changeHandler(0)} focusRipple={false}> <FormattedMessage id="sms" /></Button>
                            <Button className={classes.navigationButton} variant={this.state.value === 1 ? "contained" : "outlined"} onClick={() => this.changeHandler(1)} focusRipple={false}><FormattedMessage id="email" /></Button>
                        </ButtonGroup>
                    </div>
                </div>}
                <TabPanel value={this.state.value} index={0}>
                    {this.activeStep.component}
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    {this.activeStep.component}
                </TabPanel>

            </div>
        );
    }
}


export default withStyles(useStyles)((ResetPassword));
