import { withStyles } from '@material-ui/core';
import React from 'react';
import { FormBuilder, ActionButton } from '~/Components/From';
import ErrorAlert from '~/Components/ErrorAlert';
import Loading from '~/Components/Loading';
import { formData, formikProps, formDependency } from './data';
import useStyles from './styles';
import { FormattedMessage } from 'react-intl';
import { UserContext } from '~/Services/Providers';
import { asEntity } from '~/Hoc';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import StatusField from '../../../../../../Components/From/StatusField';

class resetPasswordForm extends React.Component {
  static contextType = UserContext;
  pageTitle = '';

  componentDidMount() {
    setTimeout(() => {
      this.pageTitle = (
        <>
          <FormattedMessage id='ResetYourPassword' />
          {'! '}
          <FormattedMessage id='ResetYourPasswordEmailText' />
        </>
      );
    }, 1000);
  }

  handleSubmit = (values, editValues = {}) => {
    this.errorMessage = undefined;
    this.values = values;
    this.loading = true;
    const { userContextObj } = this.context;
    const { themename } = userContextObj;
    this.props.entityStore.post({
      accountsId: this.context.userContextObj.accountId,
      email: values.email,
      cell: '',
      originLink: this.context.userContextObj.origin,
      ...(themename && { themename }),
    });
  };
  entityDidPosted(data) {
    this.loading = false;
    this.props.moveForward(this.values);
  }
  entityDidCatch(error) {
    this.loading = false;
    this.errorMessage = 'resetEmailError';
    this.error = true;
  }

  handleErrors = (errors) => {
    this.errorMessage = undefined;
    this.errorMessage = 'validationError';
    this.error = true;
  };

  render() {
    const { classes } = this.props;
    const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'));
    let hideMessageAndDataRate = !!fieldsToHide && fieldsToHide?.hideMessageAndDataRate;

    if (this.getLoading) {
      return <Loading />;
    }

    return (
      <>
        <StatusField>{this.pageTitle}</StatusField>
        {this.errorMessage && <ErrorAlert messageId={this.errorMessage} />}
        <div className={classes.FormWrapper}>
          <div className={classes.headerText}>
            <p>
              <FormattedMessage id='ResetYourPasswordEmailText' />
            </p>
          </div>
          <FormBuilder
            {...formikProps}
            formData={formData}
            formName='resetPasswordForm'
            formDependency={formDependency}
            editValues={this.values}
            submitAction={this.handleSubmit}
            customStyle={{ flexBasis: '100%' }}
            errorAction={this.handleErrors}
            trackFirstInteraction={true}
          >
            <p className={classes.notes}>{!hideMessageAndDataRate && <FormattedMessage id={'messageApply'} />}</p>
            <div className={classes.actionsWrapper}>
              <ActionButton
                customClass={classes.SubmitButton}
                title={'sendEmail'}
                icon={'email'}
                variant='contained'
                loading={this.loading}
              />
            </div>
          </FormBuilder>
        </div>
      </>
    );
  }
}

decorate(resetPasswordForm, {
  loading: observable,
  error: observable,
  errorMessage: observable,
  pageTitle: observable,
});
export default withStyles(useStyles)(asEntity({ storeId: 'resetPasswordEmailForm' })(observer(resetPasswordForm)));
