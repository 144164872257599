import { withStyles } from '@material-ui/core';
import axios from 'axios';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { ActionButton, FormBuilder } from '~/Components/From';
import { asEntity } from '~/Hoc';
import { canSplit, decodeBase64, queryParamsToObject } from '~/Services/Helpers';
import { UserContext } from '~/Services/Providers';
import ConfirmationAlert from '../../Components/ConfirmationAlert';
import { postMessageHeight, postMessageScrollUp } from '../../Services/Helpers';
import { validationEmailSchema } from '../../Services/SharedData/UrlValidationSchema';
import { formData, formDependency, formikProps } from './data';
import useStyles from './styles';

class NewPassword extends React.Component {
    static contextType = UserContext;
    loading = true;
    formLoading = false;
    isValid = false;
    originLink = '';
    success = null;
    successProps = {
        success: false,
        label: '',
        message: "newPasswordTokenNotValid",
        loading: false,
    };

    componentDidMount() {
        postMessageScrollUp();
        let valid = canSplit(window.location.href, '?');
        let settings = {};
        if (valid) {
            let [url, params] = window.location.href.split('?');
            settings = {
                ...queryParamsToObject(params)
            };
            
            if ( validationEmailSchema.isValidSync(settings)) {
                let { token, email, accountsId, originLink } = settings
                this.originLink = originLink
                this.token = token;
                this.email = email;
                this.accountsId = accountsId;
                this.props.entityStore.post({
                    type: 'checkToken', payload: {
                        token, email, accountsId, cell: ''
                    }
                })
            }
        }
    }

    handleSubmit = (values, editValues = {}) => {
        this.values = values;
        this.formLoading = true;
        this.sendWithIp(values);
    };

    sendWithIp = (values) => {
        let payload = {
            newPassword: values.newPassword,
            accountsId: this.accountsId,
            emailOrCell: this.email,
            token: this.token,
            ipAddress : "135.19.198.194"
        }
        let postData = (payload) => this.props.entityStore.post({
            type: 'resetPassword',
            payload,
        })

        axios.get("http://ip-api.com/json")
            .then((response) => {
                let data = response.data || {};
                postData({ ...payload, ipAddress : data.query});
            }).catch(() => postData(payload));

    };

    entityDidPosted({ type, payload }) {
        if (!!payload) {
            if (type === 'checkToken') {
                this.loading = false;
                this.isValid = true;
            } else {
                this.successProps = {
                    ...this.successProps,
                    success: true,
                    message: "newPasswordTokenValid",
                }
                this.success = true;
                setTimeout(() => {
                    window.location.href = decodeBase64(this.originLink);
                }, 5000);
            }
        }

    }
    
    
    entityDidCatch(error) {
        this.successProps = {
            ...this.successProps,
            success: false,
            message: "newPasswordTokenNotValid",
        }
        this.success = false
        this.loading = false
        this.isValid = false
        setTimeout(() => {
            window.location.href = decodeBase64(this.originLink);
        }, 5000);
    };



    render() {
        const { classes } = this.props;
        if (this.loading) {
            return <ConfirmationAlert />
        }
        if (this.isValid && !this.success) {
            return (
                <div className={classes.root}>
                    <FormBuilder {...formikProps}
                        formData={formData}
                        formName="newPassword"
                        formDependency={formDependency}
                        editValues={this.values}
                        submitAction={this.handleSubmit}
                        customStyle={{ justifyContent: 'center', gap: 5 }}
                    >
                        <div className={classes.actionsWrapper}>
                            <ActionButton customClass={classes.SubmitButton} title={'Save'}
                                variant="contained"
                                loading={this.formLoading}
                            />
                        </div>
                    </FormBuilder>
                </div>
            )
        } else return <ConfirmationAlert {...this.successProps} />
    };

}

decorate(NewPassword, {
    loading: observable,
    formLoading: observable,
    success: observable,
    isValid: observable,
})

export default withStyles(useStyles)(asEntity({ storeId: "NewPassword" })(observer(NewPassword)));