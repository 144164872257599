import React from 'react'
import useStyles from './styles';
import { Button, Icon } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useUserContext } from '../../../../../../../../../Services/Providers';
const HeaderButton = ({ onClickAction, title, icon, shouldRender, isColoured = false, intl }) => {
    const {themename} = useUserContext().userContextObj;
    const isSepecsavers = themename && themename==="Specsavers";
    const classes = useStyles();
    if (!shouldRender) return null;
    const ariaLabel =  intl.formatMessage({id: title}) + ' ' + (isColoured ? intl.formatMessage({id : 'selected'}) : intl.formatMessage({id : 'notSelected'}));
    return (
      <Button
        variant={isColoured ? 'contained' : 'outlined'}
        color={'primary'}
        aria-label={ariaLabel}
        onClick={onClickAction}
        className={classes.root}
        endIcon={!isSepecsavers && <Icon>{icon}</Icon>}
        focusRipple={false}
      >
        <FormattedMessage id={title} />
      </Button>
    );
}

HeaderButton.defaultProps = { 
    shouldRender : true,
}

export default injectIntl(HeaderButton);
