import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    overflow: 'scroll',
    height: "100%",
    justifyContent: "center",
    alignItems: 'center'
  },
  modalWrapper: {
    background: theme.palette.common.white,
    outline: "none",
    position: "relative",
    overflow: 'scroll',
    borderRadius: "10px",
    maxHeight: '75vh',
    minHeight: '50px',
    padding: theme.spacing(1),
    overflow: 'auto',
    minWidth: "900px",
    maxWidth: "750px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "400px",
      maxWidth: "450px",
      maxHeight: '95vh',
    },
  },
  header: {
    fontSize: '1.5em',
    fontWeight: "bold",
    color: "#fff",
    textAlign: "left",
    textTransform: "capitalize",
    paddingLeft: theme.spacing(2),
    marginTop: "20px",
    marginBottom: "10px",
  },
  CloseButton: {
    marginLeft: "auto",
    display: "block",
    color: theme.palette.error.main,
  },
  Header: {
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0em',
    padding: '0em .5em',
    marginTop: 0,
  }
}));

export default useStyles;
