import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from '../styles';
import { injectIntl } from 'react-intl';
import PswdReqTooltipComp from './PswdRequirementTooltip';

const PasswordRequirementTooltip = ({ passwordText = '', open, children, enableTooltip, intl }) => {
  const checkRequirement = useCallback((regex, passwordText) => {
    return regex && regex.test(passwordText);
  }, []);
  const metText = intl.formatMessage({ id: 'met' });
  const notMetText = intl.formatMessage({ id: 'notMet' });

  const requirements = useMemo(() => ([
    {
      criteria: intl.formatMessage({ id: 'passwordCriteriaSplChar' }),
      requirementRegex: /[!@#$%^&*(),.?":{}|<>]/,
    },
    {
      criteria: intl.formatMessage({ id: 'passwordCriteriaUpperCase' }),
      requirementRegex: /[A-Z]/,
    },
    {
      criteria: intl.formatMessage({ id: 'passwordCriteriaLowerCase' }),
      requirementRegex: /[a-z]/,
    },
    {
      criteria: intl.formatMessage({ id: 'passwordCriteriaNoSpace' }),
      requirementRegex: /^[^\s]+$/,
    },
    {
      criteria: intl.formatMessage({ id: 'passwordCriteriaNumeric' }),
      requirementRegex: /.*[0-9].*/,
    },
    {
      criteria: intl.formatMessage({ id: 'passwordCriteriaMinLength' }),
      requirementRegex: /.{8,}/,
    }
  ]), [intl]);

  const getRequirementStatus = useCallback((requirement) => {
    const isMet = checkRequirement(requirement.requirementRegex, passwordText);

    return {
      status: isMet ? `${metText}` : `${notMetText}`,
      label: `${requirement.criteria}`,
    };
  }, [checkRequirement, passwordText, metText, notMetText]);

  const getUnmetRequirements = useCallback(() => {
    return requirements.filter(req => !checkRequirement(req.requirementRegex, passwordText));
  }, [requirements, checkRequirement, passwordText]);

  const getAriaLabel = useCallback(() => {
    if (!enableTooltip || !open) return '';

    const requirementsStatus = getUnmetRequirements()
      .map(req => req.criteria)
      .join('. ');

    if (requirementsStatus === '') {
      return intl.formatMessage({ id: 'allPasswordRequirementsMet' });
    }

    return `${intl.formatMessage({ id: 'passwordRequirements' })}: ${requirementsStatus}`;
  }, [enableTooltip, open, getUnmetRequirements, intl]);

  const classes = useStyles();

  const passwordRequirementsDetails = useMemo(() => intl.formatMessage({ id: 'passwordRequirementDetails' }), [intl]);

  const allRequirementsMet = requirements.every((requirement) => {
    return checkRequirement(requirement.requirementRegex, passwordText);
  });

  const [ariaLabel, setAriaLabel] = useState('');

  useEffect(() => {
    if (enableTooltip && open) {
      setAriaLabel(getAriaLabel());
    }
  }, [passwordText, enableTooltip, open, getAriaLabel]);

  return (
    <div
      style={{ display: 'contents' }}
      role="region"
      aria-live="assertive"
      aria-atomic="true"
      aria-label={ariaLabel}
    >
      <Tooltip
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -35],
              },
            },
          ],
        }}
        open={enableTooltip && open}
        className={classes.tooltip}
        disableHoverListener
        arrow
        classes={{ tooltip: classes.tooltipContainer }}
        title={
          <PswdReqTooltipComp
            classes={classes}
            getRequirementStatus={getRequirementStatus}
            getUnmetRequirements={getUnmetRequirements}
            passwordRequirementsDetails={passwordRequirementsDetails}
            allRequirementsMet={allRequirementsMet}
            metText={metText}
          />
        }
      >
        {children}
      </Tooltip>
    </div>
  );
};

PasswordRequirementTooltip.propTypes = {
  passwordText: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  enableTooltip: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

PasswordRequirementTooltip.defaultProps = {
  enableTooltip: false,
  passwordText: '',
};

export default injectIntl(PasswordRequirementTooltip);