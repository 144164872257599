import * as Yup from "yup";
import { isEmpty } from '~/Services/Helpers';
import LocaleHelper from "../../../../Services/Helpers/LocaleHelper";

const getFieldsToHide = () => JSON.parse(localStorage.getItem('fieldsToHide'));
const hideDoctorField = () => getFieldsToHide()?.hideDoctorField;

export const validationSchema = Yup.object().shape({ 
    storeId: Yup.string().trim().required("Store is a required field"),
    appointmentTypeId: Yup.string().trim().required("Appointment Type is a required field"),
    doctorId: Yup.string().trim().when([], {
        is: () => {
            return !hideDoctorField();
        },
        then: Yup.string().required('Eye Care Professional is a required field'),
        otherwise: Yup.string().notRequired(),
    }),
    slotId: Yup.string().nullable().required("Appointment Date and Time is a required field").nullable(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        location: '',
        appointmentType: '',
        professional: '',
        appointmentDate: new Date(),
        storeId: '',
        appointmentTypeId: '',
        doctorId: '',
    },
};

const ParseProfessionalData = (data) => {
    const newData = []
    newData.push({ description: "", name: "Any doctor", id: "Any", })
    for (const element of data.doctors) {
        const currDescription = element.description;
        const description = currDescription.length > 97 ? currDescription.substring(0, 97) + "..." : currDescription;
        newData.push({ ...element, name: `${element.firstName} ${element.lastName}`, description: description })
    }
    return newData
};

const ParseStoreIdData = (data) => {
    return data.stores.map(store => ({
        id: store.id,
        name: `${store.name} (${store.address1} ${store.address2}, ${store.city}, ${store.province})`
      }));
};

const ParseTypeData = (data) => {
    return [...data];
};

const ParseMapData = (data) => {
    return data.stores.map(store => ({
      id: store.id,
      name: `${store.address1} ${store.address2}, ${store.city}, ${store.province} (${store.name})`,
      long: store.longitude,
      lat: store.latitude
    }));
  };

export const formData = (URI, URI2, storeId, storeOptions, handleSliderSelection, handleDateSelection) => {
    let formDataArray = [];
    formDataArray = [
        { name: "storeId", isStoreId: true, type: "dropdown", width: "68%", disabled: !!storeId, optionsURI: URI, options:storeOptions, parseData: ParseStoreIdData, required: true },
        { name: "map", type: "MapToggler", store: 1, optionsURI: URI, options:storeOptions, width: "28%", disabled: true, parseData: ParseMapData }]
    formDataArray = [
        ...formDataArray,
        { name: "appointmentTypeId", optionsURI: '', parseData: ParseTypeData, type: "dropdown", width: "100%", required: true },
        { name: "doctorId", type: "slider", width: "100%", label: "doctorSlider", parseData: ParseProfessionalData, show: false, onSelection: handleSliderSelection, required: !hideDoctorField() },
        { name: "slotId", store: null, type: "static-date", width: "98%", show: false, optionsURI: URI2, onSelection: handleDateSelection, required: true },
        { name: "earlyRequest", type: "switch", control: 'checkbox', width: "97%", },
        { name: "earlyRequestComment", type: "text", width: "25%", show: false, maxLength: 255, message: '255 chars maximum', info: 'infoPreferredTime' },
    ];
    return formDataArray;
};

export const formDependency = (accountId, accountName, storeId) => ({
    map: [
        { fields: ['storeId'], prop: 'field.store', action: (fieldValues) => fieldValues.storeId }
    ],
    appointmentTypeId: [
        {
            fields: ['storeId'], prop: 'field.show', action: (fieldValues) => {
                return !!fieldValues.storeId
           }
        },
        {
            fields: ['storeId'], prop: 'field.optionsURI', action: ({ storeId }) => {
                return `/Appointment/types?accountsId=${accountId}&storeId=${storeId}&locale=${LocaleHelper.getSelectedLocale()}`
            }
        },

    ],
    doctorId: [
        {
            fields: ['appointmentTypeId'], prop: 'field.show', action: (fieldValues) => {
                const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'))
                return !!fieldsToHide.hideDoctorField ? false : !isEmpty(fieldValues.appointmentTypeId)
            }
        },
        {
            fields: ['appointmentTypeId'], prop: 'field.appointmentTypeId', action: ({ appointmentTypeId }) => appointmentTypeId
        },
        {
            fields: ['storeId', 'appointmentTypeId'], prop: 'field.optionsURI', action: (fieldValues) => {
                if ((fieldValues.storeId || storeId) && fieldValues.appointmentTypeId) {
                    const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'));
                    if (!fieldsToHide.hideDoctorField) {
                        return `/Appointment/ecp?accountsId=${accountId}&storeId=${storeId ? storeId : fieldValues.storeId}&appointmentTypeId=${fieldValues.appointmentTypeId}&locale=en`
                    }
                    else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
        }
    ],
    earlyRequest: [
        {
            fields: ['firstSelectedDate'], prop: 'field.firstSelectedDate', action: ({ firstSelectedDate }) => {
                return firstSelectedDate;
            }
        },
        {
            fields: ['firstSelectedAppointmnetSlotsNum'], prop: 'field.firstSelectedAppointmnetSlotsNum', action: ({ firstSelectedAppointmnetSlotsNum }) => {
                return firstSelectedAppointmnetSlotsNum;
            }
        },
        {
            fields: ['next7DaysAvailability'], prop: 'field.next7DaysAvailability', action: ({ next7DaysAvailability }) => {
                return next7DaysAvailability;
            }
        },
        {
            fields: ['next14DaysAvailability'], prop: 'field.next14DaysAvailability', action: ({ next14DaysAvailability }) => {
                return next14DaysAvailability;
            }
        },
        {
            fields: ['firstSelectedDateLeadTime'], prop: 'field.firstSelectedDateLeadTime', action: ({ firstSelectedDateLeadTime }) => {
                return firstSelectedDateLeadTime;
            }
        },
        {
            fields: ['selectedTime'], prop: 'field.selectedTime', action: ({ selectedTime }) => {
                return selectedTime;
            }
        }

    ],
    earlyRequestComment: [
        {
            fields: ['earlyRequest'], prop: 'field.show', action: (fieldValues) => {
                return !!fieldValues.earlyRequest
            }
        },
    ],
    slotId: [
        {
            fields: ['doctorId', 'appointmentTypeId'], prop: 'field.show', action: ({ doctorId, appointmentTypeId }) => {
                const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'))
                return !!fieldsToHide.hideDoctorField ? !isEmpty(appointmentTypeId) && true : !isEmpty(doctorId)
            }
        },
        {
            //FIXME check if the storeId is passed from the configs and set it accordingly
            fields: ['storeId', 'appointmentTypeId', 'doctorId'], prop: 'field.optionsURI', action: (fieldValues) => {
                if ((fieldValues.storeId || storeId) && fieldValues.appointmentTypeId) {
                    const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'))

                    if (fieldValues.doctorId === "Any" || !!fieldsToHide.hideDoctorField) {
                        return `/Appointment/list?accountsId=${accountId}&companyName=${accountName}&storeId=${storeId ? storeId : fieldValues.storeId}&appointmentTypeId=${fieldValues.appointmentTypeId}&locale=en`
                    }
                    else if (fieldValues.doctorId) {
                        return `/Appointment/list?accountsId=${accountId}&companyName=${accountName}&storeId=${storeId ? storeId : fieldValues.storeId}&appointmentTypeId=${fieldValues.appointmentTypeId}&doctorId=${fieldValues.doctorId}&locale=en`
                    }
                }
            }
        },
        { fields: ['storeId'], prop: 'field.store', action: (fieldValues) => fieldValues.storeId }
    ]
});