import dayjs from "dayjs";

export const baseGAObject = {
  event: "",
  content_id: "",
  click: "",
  territory: "",
  store_type: "",
  sas_type: "",
  booking_type: "",
  booking_stage: "",
  store_name: "",
  view_state: "",
  first_available: "",
  first_appt_lead_time: "",
  day_appt_count: "",
  next_7_days_available: "",
  next_14_days_available: "",
  selected_date: "",
  selected_day: "",
  selected_time: "",
  selected_period: "",
  dpdItem: "",
  original_date: "",
  original_time: "",
  original_day: "",
  original_period: "",
};

export const getGAOriginalDate = (bookingStage) => {
  const originalFields = {};
  if (bookingStage === "reschedule") {
    const appointmentDate = localStorage.getItem("appointmentDate");
    originalFields.original_date = appointmentDate ? dayjs(appointmentDate).format("DD/MM/YYYY") : "";
    originalFields.original_time = appointmentDate ? dayjs(appointmentDate).format("HH:mm") : "";
    originalFields.original_day = appointmentDate ? dayjs(appointmentDate).format("dddd") : "";
    originalFields.original_period = getDayPeriod(dayjs(appointmentDate));
  }
  return originalFields;
};

export const getDayPeriod = (date) => {
  let day = date.date();
  let subDate = date.subtract(12, "hour");
  let subDay = subDate.date();
  if (subDay !== day) {
    return "morning";
  } else {
    let subDate = date.subtract(17, "hour");
    let subDay = subDate.date();
    if (subDay !== day) {
      return "afternoon";
    } else {
      return "evening";
    }
  }
};

export const getWink34GA = (values, actionName) => {
  //google analytics
  const { firstSelectedDate, firstSelectedDateLeadTime, firstSelectedAppointmnetSlotsNum, next7DaysAvailability, next14DaysAvailability } = values;
  let selectedTime = values.selectedTime;
  if (values.selectedTime && values.selectedTime.start) {
    selectedTime = values.selectedTime.start;
  }
  const bookingStage = localStorage.getItem("booking_stage");

  const originalFields = getGAOriginalDate(bookingStage);

  return {
    ...baseGAObject,
    ...{
      event: "button_click",
      content_id: "/wink/book",
      click: `${actionName}_${bookingStage}`,
      territory: "en_CA",
      store_type: "optics",
      sas_type: "wink",
      booking_type: localStorage.getItem("ga_booking_type"),
      booking_stage: bookingStage,
      store_name: localStorage.getItem("store"),
      view_state: "main",
      first_available: firstSelectedDate ?? "",
      first_appt_lead_time: firstSelectedDateLeadTime,
      day_appt_count: firstSelectedAppointmnetSlotsNum || "",
      next_7_days_available: next7DaysAvailability || "",
      next_14_days_available: next14DaysAvailability || "",
      selected_date: selectedTime ? dayjs(selectedTime).format("DD/MM/YYYY") : "",
      selected_day: selectedTime ? dayjs(selectedTime).format("dddd") : "",
      selected_time: selectedTime ? dayjs(selectedTime).format("HH:mm") : "",
      selected_period: selectedTime ? getDayPeriod(dayjs(selectedTime)) : "",
      dpdItem: "wink-34",
    },
    ...originalFields,
  };
};

export const getGAEventObjectOnFirstInteract = (firstIntractFieldName) => {
  //google analytics
  const bookingStage = localStorage.getItem("booking_stage");

  const originalFields = getGAOriginalDate(bookingStage);

  return {
    ...baseGAObject,
    ...{
      event: "form_interaction",
      content_id: "/wink/book",
      click: "",
      territory: "en_CA",
      store_type: "optics",
      sas_type: "wink",
      booking_type: localStorage.getItem("ga_booking_type"),
      booking_stage: bookingStage,
      store_name: localStorage.getItem("store"),
      view_state: "main",
      field: `form_start_${firstIntractFieldName}`,
      dpdItem: "wink-14",
    },
    ...originalFields,
  };
};

export const getGAEventObjectOnBlurFieldError = (fieldName, errorType) => {
  //google analytics
  const bookingStage = localStorage.getItem("booking_stage");

  const originalFields = getGAOriginalDate(bookingStage);

  return {
    ...baseGAObject,
    ...{
      event: "form_interaction",
      content_id: "/wink/book",
      click: "",
      territory: "en_CA",
      store_type: "optics",
      sas_type: "wink",
      booking_type: localStorage.getItem("ga_booking_type"),
      booking_stage: bookingStage,
      store_name: localStorage.getItem("store"),
      view_state: "main",
      field: `${fieldName}_${errorType}`,
      dpdItem: "wink-18",
    },
    ...originalFields,
  };
};
