import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    padding: 0,
    margin: 0,
    position: 'absolute !important',
    height: '1px',
    width: '1px',
    overflow: 'hidden',
    clip: 'rect(1px 1px 1px 1px)' /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */,
    clipPath: 'inset(50%)' /*modern browsers, clip-path works inwards from each corner*/,
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
