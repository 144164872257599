import React, { useEffect } from 'react';
import { useState } from 'react';
import MapGL, { Marker, NavigationControl } from 'react-map-gl';
import { TOKEN } from './data';
import Pin from './pin';
import { msgStyle, navStyle } from './styles';

export default function Map({ setMarkerValues, IsDraggable, message, options, store }) {
  const [viewport, setViewport] = useState({
    longitude: options[0]?.long ? options[0]?.long : -60,
    latitude: options[0]?.lat ? options[0]?.lat : 50,
    zoom: 14,
    bearing: 0,
    pitch: 0,
  });

  useEffect(() => {
    const selectedStore = options.find((option) => (store ? option.id === store : option.long && option.lat));
    setViewport((oldViewPort) => ({
      ...oldViewPort,
      longitude: selectedStore?.long ?? -60,
      latitude: selectedStore?.lat ?? 50,
    }));
  }, [store]);

  const getSelectedStoreMarker = (store) => {
    if (store) {
      const selectedStore = options.find((option) => option.id === store);
      if (selectedStore) {
        return (
          <Marker
            longitude={selectedStore.long}
            latitude={selectedStore.lat}
            offsetTop={-20}
            offsetLeft={-10}
            draggable={IsDraggable}
            onClick={() => setMarkerValues(selectedStore)}
          >
            {message && <div style={msgStyle}>{message}</div>}
            <Pin size={20} />
          </Marker>
        );
      }
    }
    return null;
  };

  return (
    <>
      <MapGL
        {...viewport}
        width='98%'
        height='300px'
        mapStyle='mapbox://styles/mapbox/streets-v8'
        onViewportChange={setViewport}
        mapboxApiAccessToken={TOKEN}
      >
        {store && getSelectedStoreMarker(store)}
        {!store &&
          options.map((option, index) => {
            if (option.long !== 0 && option.lat !== 0) {
              return (
                <Marker
                  key={index}
                  longitude={option.long}
                  latitude={option.lat}
                  offsetTop={-20}
                  offsetLeft={-10}
                  draggable={IsDraggable}
                  onClick={() => setMarkerValues(option)}
                >
                  {message && <div style={msgStyle}>{message}</div>}
                  <Pin size={20} />
                </Marker>
              );
            }
          })}
        <div
          className='nav'
          style={navStyle}
        >
          <NavigationControl />
        </div>
      </MapGL>
    </>
  );
}
