import formStyles from "~/Screens/GeneralStyles/formStyle";

const useStyles = (theme) => ({
    ...formStyles(theme),
    flexedButton: {
        display: "flex",
        flexBasis: '100%',
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            flexBasis: "100%",
            width: "100%",
            justifyContent: "center",
            flexDirection: "column",
        }
    }, 
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});


export default useStyles;