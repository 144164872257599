const Styles = theme => ({
  root: {
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
  },
  formWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: "1em 0px",
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignContent: 'center'
  },
  },
  title: {
    fontSize: '0.8em',
    textAlign: 'left',
    color: theme.palette.primary.main,
  },
  ActionsWrapper: {
    width: '100%',
  },
});

export default Styles;
