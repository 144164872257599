import React, { useState } from 'react';
import AppointmentForm from '../AppointmentForm';
import AppointmentFormDisabled from '../AppointmentFormDisabled';
import useStyles from './styles';
import { useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

const AppointmentsComponent = ({ editValues, deleteAppointment, handelChange, showSelector }) => {
    const classes = useStyles();
    const history = useHistory();
    const [edit, setEdit] = useState(false);
    const toggleEdit = () => {
        setEdit(!edit);
        showSelector(edit);
    }
    
    return (
        <div className={classes.root}>
            <div key={"Appointment " + editValues.id} className={classes.appointmentContainer}>
                <p className={classes.header}>
                    <FormattedMessage id="AppointmnetReference"/>&nbsp;: &nbsp;{editValues.appointmentId}
                </p>
                {edit &&
                    <AppointmentForm history={history} editValues={editValues}
                        deleteAppointment={deleteAppointment}
                        handelChange={handelChange}
                        canBeCancelled={editValues.canBeCancelled}
                        toggleEdit={toggleEdit}
                    />}
                {!edit && <AppointmentFormDisabled history={history} editValues={editValues}
                    deleteAppointment={deleteAppointment}
                    handelChange={handelChange}
                    canBeCancelled={editValues.canBeCancelled}
                    toggleEdit={toggleEdit}
                />}
            </div>
        </div>
    )
}

export default AppointmentsComponent
