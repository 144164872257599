import React from 'react'
import Success from '@material-ui/icons/DoneAll';
import Error from '@material-ui/icons/ErrorOutline';
import Loading from '@material-ui/icons/Cached';
import useStyles from './styles';
import { FormattedMessage } from 'react-intl';

const ConfirmationAlert = ({ success,
    message = null,
    label = 'verifyingToken',
    loading = true
}) => {

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div>
                {(!loading && success) && <Success style={{ color: "#4bb543" }} className={classes.Icon} />}
                {(!loading && !success) && <Error color="error" className={classes.Icon} />}
                {loading && <Loading className={classes.LoadingIcon} />}
            </div>
            <small>{label && <FormattedMessage id={label} />}</small>
            <h3 style={{ fontWeight: 500 }}>{message && <FormattedMessage id={message} />}</h3>
        </div>
    )

}



export default ConfirmationAlert