import Cancellation from '~/Screens/Cancellation';
import ConfirmBooking from '~/Screens/ConfirmBooking';
import SelectPatient from '~/Screens/SelectPatient';
import UserProfile from '~/Screens/UserProfile';
import EmailConfirm from '../Screens/EmailConfirm';
import NewPassword from '../Screens/NewPassword';
import Appointments from '../Screens/Appointments';
import BookingConfirmation from '../Screens/BookingConfirmation/BookingConfirmation';
import Auth from '../Screens/Auth';
import Book from '../Screens/Book';


export const privateRoutes = [
  { path: '/appointments', component: Appointments, exact: true },
  { path: '/confirm-booking', component: ConfirmBooking, exact: true },
  { path: '/booking-confirmation', component: BookingConfirmation, exact: true },
  { path: '/cancel-appointment/:id', component: Cancellation, exact: true },
  { path: '/select-patient', component: SelectPatient, exact: true },
  { path: '/patients', component: UserProfile, exact: true },
];

export const publicRoutes = [
  { path: '/auth', component: Auth },
  { path: '/confirmEmailToken', component: EmailConfirm, exact: true },
  { path: '/newPassword', component: NewPassword, exact: true },
  { path: '/', component: Book, exact: true },
];
