import { withStyles } from '@material-ui/core';
import ErrorAlert from '~/Components/ErrorAlert';
import React from 'react';
import { FormBuilder, ActionButton } from '~/Components/From';
import Loading from '~/Components/Loading';
import { formData, formikProps, formDependency } from './data';
import useStyles from "~/Screens/GeneralStyles/formStyle";
import { FormattedMessage } from 'react-intl';
import { UserContext } from '~/Services/Providers';
import { asEntity } from '~/Hoc';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import StatusField from '../../../../../../Components/From/StatusField';

class resetPasswordForm extends React.Component {
    static contextType = UserContext;
    pageTitle = '';

    componentDidMount(){
        setTimeout(() => {
            this.pageTitle = (
                <>
                  <FormattedMessage id='ResetYourPassword' />
                  {'! '}
                  <FormattedMessage id='ResetYourPasswordSMSText' />
                </>
              );
        }, 1000);
    }

    handleSubmit = (values, editValues = {}) => {
        this.errorMessage = undefined;
        // remove the prefix and country code from values.cell
        if (values?.cell && values?.cell.includes('+1')) {
            const simpleCell = values.cell.replace(/^(\+1)/, '');
            values.cell = simpleCell;
        }

        this.values = { ...values };
        this.loading = true;
        this.props.entityStore.post({
            accountsId: this.context.userContextObj.accountId,
            email: "",
            cell: values.cell,
            originLink: this.context.userContextObj.origin

        });
        this.context.setUserContext({
            cell: values.cell,
            resend: {
                accountsId: this.context.userContextObj.accountId,
                email: "",
                cell: values.cell,
                originLink: this.context.userContextObj.origin,
            }
        });
    };
    entityDidPosted(data) {
        this.loading = false;
        this.props.moveForward(this.values)
    }
    entityDidCatch(error) {
        this.loading = false;
        this.error = error;
        this.errorMessage = 'resetSMSError';
    }

    handleErrors = (errors) => {
        this.errorMessage = undefined;
        this.errorMessage = 'validationError';
        this.error = true;
    };

    render() {
        const { classes } = this.props;
        const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'))
        let hideMessageAndDataRate = !!fieldsToHide && fieldsToHide?.hideMessageAndDataRate;

        if (this.getLoading) {
            return <Loading />
        }

        return (
            <>
                <StatusField>{this.pageTitle}</StatusField>
                {this.errorMessage && <ErrorAlert messageId={this.errorMessage} />}
                <div className={classes.FormWrapper}>
                    <div className={classes.headerText}>
                        <p> <FormattedMessage id="ResetYourPasswordSMSText" /></p>
                    </div>
                    <FormBuilder {...formikProps}
                        formData={formData}
                        formName="resetPasswordForm"
                        formDependency={formDependency}
                        editValues={this.values}
                        submitAction={this.handleSubmit}
                        customStyle={{ flexBasis: "100%" }}
                        errorAction={this.handleErrors}
                    >
                        <p className={classes.notes}>
                            {!hideMessageAndDataRate && <FormattedMessage id={"messageApply"} />}
                        </p>

                        <div className={classes.actionsWrapper}>

                            <ActionButton customClass={classes.SubmitButton} title={'Next'}
                                icon={"arrow_forward"}
                                variant="contained"
                                loading={this.loading}
                            />
                        </div>
                    </FormBuilder>
                </div >
            </>

        )
    }
}

decorate(resetPasswordForm, {
    loading: observable,
    pageTitle: observable,
    error:observable,
    errorMessage: observable,
})
export default withStyles(useStyles)(asEntity({ storeId: "resetPasswordSMSForm" })(observer(resetPasswordForm)));