
const Styles = theme => ({
  root: {
    minWidth: 200,
    textAlign: 'left',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "& .MuiFormLabel-root.Mui-disabled": {
      color: `${theme.palette.disabled.main} !important`
    }
  },
  dropdownControl: {
    width: "100%",
    margin: '5px 0px',
    //   margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  subLoading: {
    position: 'fixed',
    top: 'calc(15%)',
    left: theme.direction === "ltr" ? 'calc(65%)' : "",
    right: theme.direction === "rtl" ? 'calc(66%)' : "",
  },
  DropdownParent: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& .MuiInputBase-root': {
      width: '100%',
    }
  },
  HideArrow: {
    display: 'block',
    width: 40,
    height: 40,
    background: 'white',
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 2,
  },
  defaultOption: {
  }

});

export default Styles;