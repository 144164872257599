
const Styles = theme => ({
    root: {
        minWidth: 200,
        textAlign: 'left',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        margin: '5px 0px',

    },
    MapButton: {
        width: "100%",
        height: `${theme.buttons.height * 0.97}px`,
        // backgroundColor: "rgba(51, 109, 222, 0.1)",
        color: theme.palette.primary.main,
        boxShadow: "none",
        '&:hover': {
            // backgroundColor: "rgba(51, 109, 222, 0.1)",
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },
        '&:active': {
            // backgroundColor: "rgba(51, 109, 222, 0.1)",
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main

        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgb(242 82 80,.5)'
        },
    },
    mapWrapper:{
        width: '100%'
    }
});

export default Styles;