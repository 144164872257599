import { IconButton, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ClickAwayListener } from '@mui/material';
import React, { useState } from 'react';
import {useIntl} from 'react-intl'

export const FieldInfo = (props) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip title={props.message} open={open}>
        <IconButton aria-label={intl.formatMessage({ id: props.info ?? 'info' })} onClick={() => setOpen(true)} onMouseOver={()=>setOpen(true)} onMouseOut={()=>setOpen(false)} onBlur={()=>setOpen(false)}>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};
