import { Button, Icon, withStyles } from "@material-ui/core";
import { decorate, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import ErrorAlert from "~/Components/ErrorAlert";
import { ActionButton, FormBuilder } from "~/Components/From";
import { StorageKeys } from "~/Configs/Storage";
import { asEntity } from "~/Hoc";
import { getStorage, isEmpty, setStorage } from "~/Services/Helpers";
import { formData, formDependency, formikProps, unAllowedFieldsMapping } from "./data";
import useStyles from "./styles";
import StatusField from "../../../../Components/From/StatusField";

class verificationCodeForm extends React.Component {
  loading = false;
  error = false;
  values = {};
  resend = false;
  pageTitle = '';

  componentWillMount() {
    this.props.setUserContext({ backHandler: () => this.props.setScreen(2) });
  }

  componentDidMount(){
    setTimeout(() => {
      const context = this.props.userContextObj;
      this.pageTitle = (
        <div>
          <FormattedMessage id={"VerificationCode"} />
          {'! '}
          <FormattedMessage id={"VerificationCodeExample1"} />
          {context.cell && context.cell}
          <FormattedMessage id={"VerificationCodeExample2"} />
          {'! '}
          <FormattedMessage id={"VerificationCodeExample3"} />
        </div>
      );
    }, 1000);
  }

  handleSubmit = (values, editValues = {}) => {
    this.error = undefined;
    this.values = values;
    const context = this.props.userContextObj;
    let postData = {
      ...context.newUserValues,
      email: context.email,
      cell: context.cell,
      username: context.cell,
      smsPinCode: values.smsPinCode,
      forceCreatePatient:true,
      isDefaultSms: true,
      accountsId: this.props.userContextObj.accountId,
      originLink: this.props.userContextObj.origin,
    };
    postData = this.removeUnAllowedFields(postData, JSON.parse(getStorage(StorageKeys.dynamicRegFields)), unAllowedFieldsMapping);

    this.props.entityStore.post({
      resend: false,
      payload: postData,
    });
  };

  removeUnAllowedFields = (data, fieldsPermissions, unAllowedFieldsMapping) => {
    let formData = data;
    Object.keys(fieldsPermissions).forEach((key) => {
      if (fieldsPermissions[key]) {
        unAllowedFieldsMapping[key].forEach((field) => {
          delete formData[field];
        });
      }
    });
    return formData;
  };

  Resend = () => {
    const { userContextObj } = this.props;
    this.resend = true;
    this.props.entityStore.post({
      resend: true,
      payload: userContextObj.resend,
    });
  };

  entityDidPosted(data) {
    if (!!data) {
      if (!this.resend) {
        if (data.message == "success") {
          this.props.setUserContext({
            isAuthenticated: isEmpty(data.token) ? false : true,
            patients: [...this.props.userContextObj.patients, data.patient],
            token: data.token,
            selectedPatientId: data.patient.patientId,
          });
          setStorage(StorageKeys.token, data.token);
          this.props.history.push("/confirm-booking");
        } else {
          this.loading = false;
          this.error = "smsPinCodeError";
        }
      } else {
        this.resend = false;
      }
    }
  }

  entityDidCatch(error) {
    this.loading = false;
    this.error = error;
  }

  render() {
    const { classes } = this.props;
    const context = this.props.userContextObj;
    return (
      <>
        <StatusField>{this.pageTitle}</StatusField>
        {this.error && <ErrorAlert messageId={this.error} />}
        <div className={classes.FormWrapper}>
          <div className={classes.headerText}>
            <h3>
              <FormattedMessage id={"VerificationCode"} />
            </h3>
            <p>
              <FormattedMessage id={"VerificationCodeExample1"} />
              {context.cell && ` <${context.cell}> `}
              <FormattedMessage id={"VerificationCodeExample2"} />
            </p>
            <p>
              <FormattedMessage id={"VerificationCodeExample3"} />
            </p>
          </div>
          <FormBuilder
            {...formikProps}
            formData={formData}
            formName="TokenBeforeAppointment"
            formDependency={formDependency}
            editValues={this.values}
            submitAction={this.handleSubmit}
            customStyle={{ flexBasis: "100%" }}
          >
            <div className={classes.flexedButton}>
              <Button
                variant="contained"
                color="primary"
                className={classes.SubmitButton}
                onClick={this.Resend}
                disabled={this.resend}
                endIcon={<Icon> {"refresh"} </Icon>}
                focusRipple={false}
              >
                <FormattedMessage id={"Resend"} />
              </Button>
              <ActionButton customClass={classes.SubmitButton}  title={"Next"} icon={"arrow_forward"} variant="contained" loading={this.loading} />
            </div>
          </FormBuilder>
        </div>
      </>
    );
  }
}

decorate(verificationCodeForm, {
  loading: observable,
  resend: observable,
  error: observable,
  pageTitle: observable, 
});

export default withStyles(useStyles)(asEntity({ storeId: "ConfirmPIN" })(observer(verificationCodeForm)));
