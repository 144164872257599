import ApiConfigs from '../../Configs/Api';

export const isEmpty = value => value === undefined
    || value === null
    || (typeof value === 'object' && Object.keys(value).length === 0)
    || (typeof value === 'string' && value.trim().length === 0);

export const fixedStorageLink = ApiConfigs.storageUrl;

export const getMedia = path => fixedStorageLink + path


export function UpperCaseFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


// compare two object that have the same structure and check if they are equals
export const compareObjects = (firstVal , secondVal) => JSON.stringify(firstVal) === JSON.stringify(secondVal);

export function FullscreenHandler(ScreenElem) {
    const elem = ScreenElem;


    this.openFullScreen = () => {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
        }
    };


    this.closeFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    };
}


export const onlyOneOfProps = (fields) => (props, propName, componentName) => {
    let isFieldProvided = false;
    let errorObj = new Error(`${componentName}.props ((${propName}))only one of the following props should be provided [${Object.keys(fields)}] `);

    Object.keys(fields).forEach(field => {
        if (!!props[field]) {
            if (isFieldProvided)
                return errorObj;
            isFieldProvided = true;
        }
    });

    if (!isFieldProvided)
        return errorObj;
};


export const canSplit = (str, separator) => str.indexOf(separator) === -1 ? false : true;


export const decodeBase64 = (str) => decodeURIComponent(escape(window.atob(str)))
