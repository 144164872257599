import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backButton: {
    minWidth: '40px',
    color: '#222',
    '&:hover': {
      background: 'none',
      borderColor: 'none',
    },
    '&:active': {
      backgroundColor: 'none',
      borderColor: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: 'none',
    },

    fontSize: '0.8em',
    textTransform: 'uppercase',
  },

  "& .MuiButton-label": {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  iconWrapper: {
    width: '16px',
    display: 'flex',
    alighItems: 'center',
    justifyItems: 'center',
  },

  icon: {
    fontSize: '2em',
  }
}));

export default useStyles;
