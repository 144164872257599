import axios from "axios";
import { create } from "apisauce";
import ApiConfigs from "../../Configs/Api";

let axiosInstance = axios.create({
  baseURL: document.getElementById("wink-online-module")?.getAttribute("data-baseURL"),
  timeout: ApiConfigs.timeout,
  headers: {
    Accept: `application/json;v=${ApiConfigs.version}`,
    "Content-Type": "application/json",
  },
});

export const setApiLocale = (locale) => {
  axios.defaults.headers.common["Accept-Language"] = locale;
};

let Api = create({ axiosInstance: axiosInstance });
let CancelToken = axios.CancelToken;
let requestsSourceCancellation = CancelToken.source();

export const createAPI = (baseURL) => {
  if (baseURL) {
    axiosInstance = axios.create({
      baseURL,
      timeout: ApiConfigs.timeout,
      headers: {
        Accept: `application/json;v=${ApiConfigs.version}`,
        "Content-Type": "application/json",
      },
    });
    Api = create({ axiosInstance: axiosInstance });
    CancelToken = axios.CancelToken;
    requestsSourceCancellation = CancelToken.source();
  }
};

const endpoints = {
  account: {
    login: (data, headers) => Api.post("login/patient", data, { ...headers, cancelToken: requestsSourceCancellation.token }),
    sendToken: (data, headers) => Api.post("Patient/sendToken", data, { ...headers, cancelToken: requestsSourceCancellation.token }),
    ForgotPassword: (data, headers) => Api.post("Patient/ForgotPassword", data, { ...headers, cancelToken: requestsSourceCancellation.token }),
    resetPassword: (data, headers) => Api.post(`Patient/ResetNewPassword`, data, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  booking: {
    get: (data, headers) => Api.get(`Appointment?accountsId=${data.accountsId}&companyName=${data.companyName}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    post: (data, headers) => Api.post("Appointment", { ...data, forceBookNewAppointment: true }, { ...headers, cancelToken: requestsSourceCancellation.token }),
    cancel: (data, headers) => Api.post("Appointment/cancel", data, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  storeLogo: {
    get: (data, headers) => Api.get(`StoreLogo?accountsId=${data.accountId}&companyName=${data.companyName}&storeId=${data.storeId}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token, responseType:'arraybuffer' }),
  },
  appointments: {
    get: (data, headers) => Api.get(`Appointment/patient/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  confirmLoginToken: {
    post: (data, headers) => Api.post(`Patient/confirmLoginToken`, data, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  getInfo: {
    get: (data, headers) => Api.get(`Appointment?accountsId=${data.accountId}&companyName=${data.accountName}${data.storeId?('&storeId=' + data.storeId):''}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  confirmResetPassword: {
    post: (data, headers) => Api.post(`Patient/ValidateForgotPasswordPinCode`, data, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  patient: {
    get: (data, headers) => Api.get(`Patient/list?email=${data.email}&mobile=${data.mobile}&match=any`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    patientById: (data, headers) => Api.get(`Patient/${data.id}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
    createPatient: (data, headers) => Api.post("Patient", data, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  user:{
    getImage:(data, headers)=>Api.get(`User/Image/${data.accountId}?userId=${data.userId}&width=60&height=60`,{}, {...headers, cancelToken: requestsSourceCancellation.token, responseType:'blob'})
  },
  dynamicComponent: {
    get: (data, headers) => Api.get(`${data.uri}`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  gender: {
    get: (data, headers) => Api.get(`Patient/gender/list`, {}, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  GenericHandlers: {
    get: (data, headers) => Api.get(data.uri, data.body, { ...headers, cancelToken: requestsSourceCancellation.token }),
    getOne: (data, headers) => Api.get(data.uri, data.body, { ...headers, cancelToken: requestsSourceCancellation.token }),
    post: (data, headers) => Api.post(data.uri, { ...headers, cancelToken: requestsSourceCancellation.token }),
    delete: (data, headers) => Api.delete(data.uri, data.body, { ...headers, cancelToken: requestsSourceCancellation.token }),
    put: (data, headers) => Api.put(data.uri, data.editedData, { ...headers, cancelToken: requestsSourceCancellation.token }),
    patch: (data, headers) => Api.patch(data.uri, data.editedData, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
  Profile: {
    post: (data, headers) => Api.post(`PatientProfile`, data, { ...headers, cancelToken: requestsSourceCancellation.token }),
  },
};

export default endpoints;
